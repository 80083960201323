import { Button } from "@mui/material"
import React, { useEffect, useState } from "react"

/**
 * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
 * before a user is prompted to "install" a web site to a home screen on mobile.
 *
 */
interface BeforeInstallPromptEvent extends Event {
  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed"
    platform: string
  }>

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>
}

export function PWA_DownloadEvent(): any | undefined{
  const [prompt, setState] = useState<BeforeInstallPromptEvent | null>(null)

  const promptToInstall = async () => {
    console.log(prompt)

    if (prompt) {
      await prompt.prompt();
    } else {
      // throw new Error('Tried installing before the browser sent "beforeinstallprompt" event');
      return false
    }
  };

  const ready = (e: BeforeInstallPromptEvent) => {
    e.preventDefault()
    setState(e)
  }
  useEffect(() => {
    window.addEventListener("beforeinstallprompt", ready as any)
    return () => {
      window.removeEventListener("beforeinstallprompt", ready as any)
    }
  }, [])

  return promptToInstall
}
