import React, { useCallback, useState } from "react"
import Box from "@mui/material/Box"
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridValueGetterParams,
} from "@mui/x-data-grid"
import { useEffect } from "react"
import SaveIcon from "@mui/icons-material/Save"
import DeleteIcon from "@mui/icons-material/Delete"
import ArticleAPI, { articleDataModel } from "../../ServerAPI/ArticleAPI"
import EditIcon from "@mui/icons-material/Edit"
import CancelIcon from "@mui/icons-material/Close"
import { render } from "react-dom"
import { Alert } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import EventDialogBase from "../R_Base/Dialog/EventDialogBase"
import SimpleTransitionsSlide from "./TransitionsSlide"
import Backdrop from "../R_Base/backdrop/backdropBase"
import { R_Display } from "../R_Style/R_Style"
//DataGridDemo Example
const columns: GridColDef[] = [{ field: "id", headerName: "ID", width: 90 }]
const rows = [{ id: 1, lastName: "Snow", firstName: "Jon", age: 14 }]

type DataGridDemoProps = {
  rows: Array<any>
  column: GridColDef[]
  deletedMethod: Function
  updateMethod: Function
}

export default function DataGridDemo(Props: DataGridDemoProps) {

  //主要資料欄位
  const [mainData, set_mainData] = useState<any[]>(Props.rows)
  //MUI 欄位控制器
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
  const [choseRowData, set_choseRowData] = React.useState<any>("")

  // const [rowUpdate, set_rowUpdate] = React.useState<dataModel>()
  // const [mainColumn, set_mainColumn] = useState<GridColDef[]>([])

  //初始資料
  useEffect(() => {
    set_mainData(Props.rows)
  }, [Props.rows])

  //TODO開啟編輯模式
  const handleEditClick = (rowData: any) => () => {
    setRowModesModel({ ...rowModesModel, [rowData.id]: { mode: GridRowModes.Edit, fieldToFocus: "img" } })
    const MUI = JSON.stringify(rowModesModel, null, 2)
    console.log(rowData)
    // const choseRowData = JSON.parse(rowData.row)
    // set_choseRowData(rowData.row)
    console.log("MUI 欄位控制器" + MUI)
  }

  //TODO儲存按鈕 控制狀態
  const handleUpdateClick = async (rowData: any) => {
    set_check(false) //關閉確認視窗
    // 將該列狀態設為 View 模式
    setRowModesModel((prev) => ({
      ...prev,
      [rowData.id]: { mode: GridRowModes.View },
    }));
  }

  //TODO刪除按鈕 控制狀態
  const handleDeleteClick = async (rowData: any) => {
    try {
      set_check(false)
      console.log(rowData)
      // console.log(rowData.rowData)
      console.log(rowData.guid)
      //刪除現在資料
      set_mainData((prevData) => prevData.filter((filter) => filter.guid !== rowData.guid))
      // {id: 1, columns: Array(8), row: {…}}
      await Props.deletedMethod(rowData.guid)
    } catch (error) {
      console.error("Error handling delete click:", error)
    }
  }

  //TODO取消按鈕
  const handleCancelClick =
    ({ id }: any) =>
      () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.View, ignoreModifications: true },
        })
      }

  //TODO MUI processRowUpdate 會獲取每個欄位更動數值
  const processRowUpdate = useCallback(async (newRow: GridRowModel, oldRow: any) => {

    try {
      const updatedRow = newRow
      console.log("舊資料" + JSON.stringify(oldRow))

      let retag: any = Array.isArray(updatedRow.tag) ? updatedRow.tag : updatedRow.tag.split(",")

      let redata = {
        code: updatedRow.code,
        title: updatedRow.title,
        date: new Date(updatedRow.date),
        tag: retag,
        img: updatedRow.img,
        url: updatedRow.url,
      }
      console.log("更新成功!" + JSON.stringify(redata))

      await Props.updateMethod(newRow.guid, redata)
      return updatedRow
    } catch (ex) {
      console.log(ex)
    }
  }, [])

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  //內部常駐編輯欄位
  const columns: GridColDef[] = []
  columns.push(...Props.column)
  columns.push({
    field: "action",
    headerName: "編輯",
    type: "actions",
    width: 200,
    cellClassName: "actions",
    //getActions 獲取該欄位位置序號 id
    getActions(id: any): any[] {
      //如果開啟編輯模式
      const isInEditMode = rowModesModel[id.id]?.mode === GridRowModes.Edit
      if (isInEditMode) {
        // console.log(id.row)
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: "primary.main",
            }}

            //設定儲存欄位功能
            onClick={() => (
              set_check(!check),
              set_dialogContent({
                text_check: "儲存",
                data: id.row,
                event: handleUpdateClick,
              })
            )}
          />,
          <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(id)} color="inherit" />,
        ]
      }
      return [
        <GridActionsCellItem color="inherit" icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(id)} />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          sx={{
            color: "primary.main",
          }}
          // onClick={handleDeleteClick(id)}
          onClick={() => (
            set_check(!check),
            set_dialogContent({
              text_check: "刪除",
              data: id.row,
              event: handleDeleteClick,
            })
          )}
        />,
      ]
    },
  })


  const [check, set_check] = useState(false)
  // const aaa: {id:number,{...articleDataModel: any}}
  const [dialogContent, set_dialogContent] = useState<{ text_check: string, data: { id: number } & articleDataModel, event: Function }>({
    text_check: '',
    data: {
      id: 0,
      code: '',
      title: '',
      date: new Date(),
      tag: null,
      img: '',
      url: '',
    },
    event: () => { },
  })

  return (
    <>
      <Backdrop open={check} set_open={() => set_check(!check)}>
        <div style={{ zIndex: 4900, width: "100vw", height: "100vh", ...R_Display("row", "center", "center") }}>
          <EventDialogBase
            text_title={"是否要" + dialogContent.text_check + "第 " + dialogContent.data.id + " 筆資料"}
            width={"300px"} isActive={check}
            set_isActive={function (value: React.SetStateAction<boolean>): void {
              set_check(false)
            }}
            text_content={dialogContent.data.code}
            text_s_content={dialogContent.data.title}

            Button_ok={dialogContent.text_check}
            Button_cancel="取消"
            event={() => {
              // console.log(dialogContent.row_id)
              dialogContent.event(dialogContent.data)
            }}
          />
        </div>
      </Backdrop>

      <Box sx={{ height: "100%", width: "100%" }}>
        <DataGrid
          rows={mainData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) => error}
          onRowEditStop={handleRowEditStop}
          editMode="row"
          pageSizeOptions={[20]}
        // checkboxSelection
        // disableRowSelectionOnClick
        />
      </Box>
    </>
  )
}
