import React, { useEffect, useState } from "react"
import ResponsiveAppBar from "../../@R036/R_Mui/AppBar"
import SimpleTransitionsSlide from "../../@R036/R_Mui/TransitionsSlide"
import DrawerList from "../../Component/drawerList"
import Backdrop from "../../@R036/R_Base/backdrop/backdropBase"
import Button_two from "../../@R036/R_Base/Button/button_two"
// import Backdrop from "../../@R036/R_Base/backdrop/backdropBase"
export default function CompanyHome() {
  const [isLoading, set_isLoading] = React.useState(false)
  const [isMenu, set_isMenu] = useState(false)
  const [asdasd,_sadsad] = useState(false)

  useEffect(() => {
    const simulateLoading = async () => {
      // 使用 Promise 包裝 setTimeout
      await new Promise((resolve) => setTimeout(resolve, 3000))
      // 載入完成後將 isLoading 設為 false
      set_isLoading(false)
    }

    set_isLoading(true)
    simulateLoading() // 模擬載入
  }, []) // 依賴陣列為空，表示只在組件首次渲染時執行


  const drawlist = [
    { title: "首頁", scr: "/blog" },
    { title: "文章", scr: "/article" },
    { title: "技術", scr: "#technical" },
    { title: "關於", scr: "/profile" },
  ]
  

  return (
    <>
     <ResponsiveAppBar
        rightTool={[
          <Button_two
            active={() => {
              set_isMenu(!isMenu)
            }}
            isOpen={isMenu}
            colorModel="black"
          />,
        ]}
        style={{ boxShadow: "none" }}
        list={drawlist}
        Logo={<>Raylandex</>}
        Opend={() => set_isMenu(!isMenu)}
        color_Model={"black"}
      >
        <>
          <SimpleTransitionsSlide touch timeout={[1000, 400, 250]} transition={"left"} isActive={isMenu} position_row={"end"} position_column={"start"}>
            <div style={{ zIndex: 5001, height: "100vh" }}>
              <DrawerList
                active={() => {
                  set_isMenu(!isMenu)
                }}
              />
            </div>
          </SimpleTransitionsSlide>

          <Backdrop
            open={isMenu}
            set_open={function (): void {
              set_isMenu(!isMenu)
            }}
          ></Backdrop>
        </>
      </ResponsiveAppBar>
  
    </>
  )
}
