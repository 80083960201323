import React, { useState } from "react";
// import "./Carousela.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Divider } from "@mui/material";
import { R_Display_bata } from "../../R_Style/R_Style";
import "./Carousela.css";

//須帶入CarouselList資料格式參考


export type CarouselProp = {
  content: Array<{
    Element: React.ReactElement;
    title: string;
    subtitle: string;
    tag: string;
  }>;
};

const Carousel = (props: CarouselProp) => {
  const [selectedIdx, setSelectedIdx] = useState(0);
  const totalIdx = props.content.length;

  const prevSlide = () => {
    const newIdx = (selectedIdx - 1 + props.content.length) % props.content.length;
    // console.log('newIdx' + newIdx);
    setSelectedIdx(newIdx);
  };

  const nextSlide = () => {
    const newIdx = (selectedIdx + 1) % props.content.length;
    // console.log('newIdx' + newIdx);
    setSelectedIdx(newIdx);
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [TextActivety, setTextActivety] = useState("");

  const handleClick = () => {
    // 防止按鈕被連續點擊
    setIsButtonDisabled(true);

    // 模擬一段時間後恢復按鈕可點擊
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 800);
  };

  const handleText = () => {
    // 防止按鈕被連續點擊
    setTextActivety("textactivety");

    // 模擬一段時間後恢復按鈕可點擊
    setTimeout(() => {
      setTextActivety("");
    }, 800);
  };

  return (
    <div className="carousel">
      <button
        className="carouselButton prevButton"
        onClick={() => {
          prevSlide();
          handleClick();
          handleText();
        }}
        disabled={isButtonDisabled}
      >
        <ArrowBackIosNewIcon />
      </button>
      <button
        className="carouselButton nextButton"
        onClick={() => {
          nextSlide();
          handleClick();
          handleText();
        }}
        disabled={isButtonDisabled}
      >
        <ArrowForwardIosIcon />
      </button>

      <div className="content">
        {props.content.map((item, index) => (
          <div key={index}>
            <div style={{...R_Display_bata().display_row, ...R_Display_bata().colum_center }}>
              <div className={`info_box ${TextActivety}`} >
                <div className={`title `}>{index === selectedIdx ? item.title : ""}</div>
                <div className={`text_content `}>{index === selectedIdx ? item.subtitle : ""}</div>
                {index === selectedIdx ? <Divider sx={{ zIndex: 2000, backgroundColor: "white", marginBottom: 2, width: "100px" }} /> : <></>}
                <div className={`text_content2 `}>{index === selectedIdx ? item.tag : ""}</div>
              </div>
            </div>

            <div
              className={`card_item ${index === selectedIdx ? `selected` : ``} ${index === (selectedIdx - 1 + totalIdx) % totalIdx ? `preIndex` : ``
                } ${index === (selectedIdx + 1) % totalIdx ? `nextIndex` : ``}`}
            >
              {item.Element}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
