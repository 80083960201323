export async function MovieAPI() {
    try {
        // const response = await fetch("http://localhost:3002/api/article", {
        const response = await fetch("https://r036-center.vercel.app/api/movie", {
          method: "GET",
          headers: {
            "Content-Type": "application/json", // Set the correct Content-Type
            // Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        if (!response.ok) {
          throw new Error(`Failed to read movie. Status: ${response.status}`)
        }
        const result = await response.json()
        console.log(result)
        return result
      } catch (error) {
        console.log(error)
      }
}