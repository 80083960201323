import { useEffect } from "react"
import "./transitionBlock.css"


type TransitionBlockProps = {
    children?: React.ReactNode
    div_id: string
    // backdrop?: boolean
    // open: boolean
    // set_open: Function
    time: number
}


export function TransitionBlock(Props: TransitionBlockProps) {

    const div_id = Props.div_id

    useEffect(() => {


        const div = document.getElementById(div_id);



        const timer = setTimeout(() => {
            div?.classList.add('show');
          }, Props.time); // 延遲以觸發 transition


        return () => {

            clearTimeout(timer); // 清除定時器
          };
    }, []) // 空数组表示只在组件挂载和卸载时执行

    // function showDiv() {
    //     const div = document.getElementById(div_id);
    //     div?.classList.remove('hidden');
    //     setTimeout(() => {
    //         div?.classList.add('show');
    //     }, 10); // 延遲以觸發 transition
    // }

    return (
        <div id={div_id} className="myDiv">
            {Props.children}
        </div>
    )
}