import { Button } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import DrawerList from "../../@R036/R_Base/Drawer/DrawerList"
import ResponsiveAppBar from "../../@R036/R_Mui/AppBar"
import DataGridDemo from "../../@R036/R_Mui/DataGrid"
import TemporaryDrawer from "../../@R036/R_Mui/TemporaryDrawer"
import ArticleAPI, { articleDataModel } from "../../ServerAPI/ArticleAPI"
import { JWT_Verify } from "../../ServerAPI/LoginAPI"

const BackSystem = () => {

  const articleAPI = new ArticleAPI()
  const [model, set_model] = useState<articleDataModel>(articleAPI.dataModeaaal)
  const [mainFormData, set_mainFormData] = useState<Array<any>>([])
  const navigate = useNavigate()

  useEffect(() => {
    Read_Article_api()
  }, [])

  //提交表單
  const handleSubmit = async (e: any) => {
    e.preventDefault()
    const blogData:articleDataModel = {
      code: model.code,
      title: model.title,
      date: new Date(model.date),
      tag: model.tag,
      img: model.img,
      url: model.url,
    }
    console.log("Submitted:", blogData)
    await articleAPI.Create_Article(blogData)
    Read_Article_api()
  }

  //讀取資料
  async function Read_Article_api() {
    try {
      console.log("Fetching articles...")
      const startTime = Date.now()
      let articles = await articleAPI.Read_Article()
      const endTime = Date.now()
      console.log("Articles fetched in", endTime - startTime, "milliseconds")
      console.log("Articles:", articles)

      // 使用 .map() 來返回新的陣列
      articles = articles.map((article: { id: any }, index: number) => {
        return {
          ...article, // 保留其他屬性
          id: index + 1 // 添加 id 屬性，從 1 開始
        };
      });
      console.log(articles);
      set_mainFormData(articles)
    } catch (error) {
      console.error("Error fetching Read_Article_api():", error)
    }
  }

  //驗證
  async function LoginCheck() {
    console.log(sessionStorage.getItem("token"))
    if (sessionStorage.getItem("token")) {
      try {
        const loginCheckResult = await JWT_Verify()

        console.log(loginCheckResult)

        if (loginCheckResult === true) {
          console.log("JWT_Verify成功")
          return
        }
      } catch (error) {
        console.error("Error during JWT verification:", error)
      }
    }
    console.log("未登入成功，返回至登入介面")
    sessionStorage.removeItem("token")
    navigate("/blog/6f0f4816-0ff4-4215-9eb3-2091fa846e9e/login")
  }

  //驗證
  useEffect(() => {
    LoginCheck()
    console.log("每分钟执行一次")
    const intervalId = setInterval(() => {
      console.log("Running LoginCheck...")
      LoginCheck()
    }, 60000)
    return () => clearInterval(intervalId)
  }, [])

  //更新資料
  const handleChange = (e: any) => {
    const { name, value } = e.target
    console.log(name + value)
    set_model((prevData: any) => ({
      ...prevData,
      [name]: value,
    }))
    console.log("handleSubmit:" + JSON.stringify(model))
  }

  // const 登出 = () => {
  //   sessionStorage.removeItem("token")
  //   navigate("/blog/6f0f4816-0ff4-4215-9eb3-2091fa846e9e/login")
  // }
  // const aaa = [
  //   {    
  //     title: "帳號資料",
  //     method: () => console.log("帳號資料被點"),
  //   },
  //   {
  //     title: "登出",
  //     method: 登出,
  //   },
  // ]

  return (
    <>
      <div style={{ height: "100px" }}></div>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="code">編號：</label>
          <input type="text" id="code" name="code" value={model.code} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="title">標題：</label>
          <input type="text" id="title" name="title" value={model.title} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="date">日期：</label>
          <input type="date" id="date" name="date" onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="tag">標籤（用逗號分隔）：</label>
          <input type="text" id="tag" name="tag" value={model.tag} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="img">圖片 URL：</label>
          <input type="text" id="img222" name="img" value={model.img} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="url">文章 URL：</label>
          <input type="text" id="url" name="url" value={model.url} onChange={handleChange} />
        </div>
        <button type="submit">提交</button>
      </form>
      <DataGridDemo rows={mainFormData} deletedMethod={articleAPI.Delete_Article} updateMethod={articleAPI.Update_Article} column={dataGridDemoColunm} />
    </>
  )
}

const dataGridDemoColunm: GridColDef[] = [
  {
    field: "id",
    headerName: "編號",
    width: 30,
    type: "string",
    editable: false,
  },
  {
    field: "title",
    headerName: "標題",
    minWidth: 200,
    flex: 3,
    type: "string",
    editable: true,
  },
  {
    field: "tag",
    headerName: "標籤",
    minWidth: 100,
    flex: 1,
    type: "string",
    editable: true,
  },
  {
    field: "code",
    headerName: "序號",
    minWidth: 100,
    flex: 1,
    type: "string",
    editable: true,
  },
  {
    field: "date",
    headerName: "日期",
    minWidth: 100,
    flex: 1,
    type: "date",
    editable: true,
    valueGetter: (params) => {
      // 将日期字符串转换为 Date 对象
      // return Date(params.value).toLocaleDateString('zh-TW');
      return new Date(params.value)
    },
  },
  {
    field: "img",
    headerName: "圖片",
    minWidth: 200,
    flex: 2,
    type: "string",
    editable: true,
  },
  {
    field: "url",
    headerName: "連結",
    minWidth: 200,
    flex: 2,
    type: "string",
    editable: true,
  },
]

export default BackSystem
