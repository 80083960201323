import React, { useState } from "react";
import "./BaseCard.css";

export type BaseCardProps = {
  image: string;
  width?: number;
  height?: number;
  margin?: number;
  isSelected?: boolean; // 新增屬性
  onClick?: () => void; // 點擊事件處理器
};

const BaseCard = ({
  image,
  width = 130,
  height = 180,
  margin = 0,
  isSelected = false,
  onClick,
}: BaseCardProps) => {
  return (
    <div
      id="BaseCard"
      className={`BaseCard ${isSelected ? "selected" : ""}`} // 根據 isSelected 狀態添加 CSS 類
      style={{ width: width + "px", height: height + "px", margin: margin + "px" }}
      onClick={onClick} // 綁定點擊事件
    >
      <img src={image} className="background-image" alt="R036" />
    </div>
  );
};

export default BaseCard;
