import { url } from "inspector"
import "./profileCard.css"
import Arrow_direction from "../../@R036/R_Asset/Arrow_direction/Arrow_direction"
import { Avatar, Button, Chip, ClickAwayListener, Divider, List, ListItem, ListItemAvatar, ListItemText, ThemeProvider, Tooltip, createTheme } from "@mui/material"
import { R_Display, R_Display_bata } from "../../@R036/R_Style/R_Style"
import { animated, config, useChain, useSpring, useSpringRef, useSprings, useTrail, useTransition } from "@react-spring/web"
import { useEffect, useState } from "react"
// import A from '../../public/@R036_images/resume/personal.png'
import "../../@R036/R_Style/R_FontTheme.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import styled from "@emotion/styled"
import InstagramIcon from "@mui/icons-material/Instagram"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import React from "react"
import LoadingPage from "../home/loadingPage"
import { TransitionBlock } from "../../@R036/R_Base/TransitionBlock/transitionBlock"
import { Theme } from "../../@R036/R_Mui/Theme"
// import imageToAdd from "./../assets/@R036_images/logo.png";
export default function Profile() {
  const openapi = useSpringRef()
  const [open, set] = useState(false)

  const [props, apiaa] = useSpring(() => ({
    ref: openapi,
    from: { height: 0 },
  }))

  const handleClick = () => {
    openapi.start({
      to: {
        height: open ? 900 : 0,
      },
    })
  }

  const [trails, api] = useTrail(
    9,
    () => ({
      from: { scale: 0, opacity: 0 },
      to: { scale: 1.6, opacity: 1 },
      config: {
        mass: 20,
        tension: 320,
        // friction: 15
      },
      delay: 600,
    }),
    []
  )

  useEffect(() => {
    const asdasd = window.innerHeight
    console.log(asdasd)
    const handleScroll = () => {
      // 获取滚动距离
      const scrollY = window.scrollY
      console.log(scrollY)
      // 设置触发滚动效果的阈值
      if (scrollY == 0) {
        console.log("asdsa")
        set(false)
      }
    }

    // 监听滚动事件
    window.addEventListener("scroll", handleScroll)
    // 清理事件监听，避免内存泄漏
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, []) // 空数组表示只在组件挂载和卸载时执行

  let startY: number

  const handleTouchStart = (event: any) => {
    // 记录触摸起始位置的纵坐标
    startY = event.touches[0].clientY
  }

  const handleTouchMove = (event: any) => {
    // 获取当前触摸位置的纵坐标
    const currentY = event.touches[0].clientY

    // 计算纵向移动距离
    const deltaY = currentY - startY

    // 判断手势方向，这里以向下滑动为例
    if (deltaY < 0) {
      console.log("向下滑动")
      // set(true)
      // handleClick()
      set(true)
      setTimeout(() => {
        scrollToElement("skill_focus")
      }, 100)
    }

    // 更新起始位置
    startY = currentY
  }

  // 监听触摸开始和移动事件
  document.addEventListener("touchstart", handleTouchStart)
  //偵測滑動跳轉事件
  // document.addEventListener("touchmove", handleTouchMove)

  const scrollToElement = (id: string) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: "smooth" })
    }
  }

  const data = new dataClass().data
  const data2 = new dataClass().data2
  const data3 = new dataClass().data3

  const [trail, api111] = useTrail(
    data.length,
    () => ({
      config: { mass: 5, tension: 2000, friction: 150 },
      opacity: open ? 1 : 0,
      x: open ? 0 : -30,
      // height: open ? 110 : 0,
      from: { opacity: 0, x: -30, margin: 15 },
    }),
    [open]
  )

  useEffect(() => {
    handleClick()
  }, [open])


  const [openMail, set_openMail] = useState(false)
  useEffect(() => {
    const elementById = document.getElementById("MailOutlineIcon")
    if (openMail) {
      elementById?.classList.add("scale_show")
    } else {
      elementById?.classList.remove("scale_show")
    }
  }, [openMail])
  const [isLoading, set_isLoading] = React.useState(false)
  //圖片是否載入
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  function showDiv() {
    const div = document.getElementById('myDiv');
    div?.classList.remove('hidden');
    setTimeout(() => {
      div?.classList.add('show');
    }, 10); // 延遲以觸發 transition
  }

  return (
    <>
      <ThemeProvider theme={Theme}>
        <div id="Profile_content">
          {imageLoaded ? <></> : <LoadingPage />}
          <div className="Profile_card">
            <div className="circle_box">
              <div className={"grid-container"}>
                {trails.map((props, index) => (
                  <animated.div
                    key={index}
                    style={{
                      width: "5px",
                      height: "5px",
                      backgroundColor: "rgb(147, 147, 147)",
                      borderRadius: "100%",
                      ...props,
                    }}
                  ></animated.div>
                ))}
              </div>
            </div>
            <div className="firstinfo">
              <div className="profileinfo">
                <img onLoad={handleImageLoad} style={{ marginTop: 20 }} src={"/@R036_images/main/R-036(nullbright-xs).jpg"} />

                {/* 仔入後才顯示 */}
                {imageLoaded ?
                  <>
                    <TransitionBlock time={300} div_id={"block_1"}>
                      <>
                        <div style={{ marginTop: "25px" }} className="subtitle">
                        </div>


                        <div style={{ marginTop: 10, marginBottom: "5px" }} className="Font_Family_Bebas_Neue  title">
                          <span className="One_theme_number"> R-036</span>

                        </div>
                        {/* <div className="subtitle" style={{}}>全端軟體工程師</div> */}
                        {/* feature/connetion_nas 改版*/}
                        <p className="bio content">擔任全端軟體工程師，主要負責項目為React網站系統、NodeJS API後端系統、NAS伺服器架設，並擅長將設計美學融入軟體開發中</p>
                        <div style={{ marginTop: "25px" }} className="subtitle">
                          經歷
                        </div>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>1
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="國際人工智慧管理研究所" secondary="研究所" />
                          </ListItem>

                          <ListItem>
                            <ListItemAvatar>
                              <Avatar className="One_theme_number">2
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="資訊管理系" secondary="大學" />
                          </ListItem>

                        </List>
                        <Divider />
                      </>
                    </TransitionBlock>

                    <TransitionBlock time={600} div_id={"block_2"}>
                      <div style={{ marginTop: "25px" }} className="subtitle">
                        開發項目
                      </div>

                      <p style={{ width: "100%", flexWrap: "wrap", ...R_Display("row", "flex-start", "flex-start") }}>
                        <Chip sx={{ margin: "5px 5px 5px 0px" }} className="bio content" size="small" label="React專案" variant="filled" />
                        <Chip sx={{ margin: "5px 5px 5px 0px" }} className="bio content" size="small" label="NodeJS後端" variant="filled" />
                        <Chip sx={{ margin: "5px 5px 5px 0px" }} className="bio content" size="small" label="Synology伺服器建置" variant="filled" />
                        <Chip sx={{ margin: "5px 5px 5px 0px" }} className="bio content" size="small" label="美學與系統學設計" variant="filled" />
                      </p>

                    </TransitionBlock>


                    <Divider style={{ marginTop: "20px" }} />

                    <TransitionBlock time={1200} div_id={"block_4"}>
                      <div style={{ marginTop: "25px" }} className="subtitle">
                        聯絡方式
                      </div>
                      <p style={{ ...R_Display("row", "flex-start", "flex-start"), padding: "20px" }}>
                        <Tooltip title="@art.code.design">
                          <a href="https://www.instagram.com/art.code.l/">
                            <InstagramIcon />
                          </a>
                        </Tooltip>

                        <Tooltip onClick={() => set_openMail(!openMail)} disableFocusListener open={openMail} title="r036.software@gmail.com">
                          <a id="MailOutlineIcon" className="R_animationScale" style={{ marginLeft: "25px" }}>
                            <MailOutlineIcon />
                          </a>
                        </Tooltip>
                      </p>

                    </TransitionBlock>

                  </>
                  :



                  <></>}
                {/* <button onClick={() => { showDiv() }}>{`${imageLoaded}`}</button> */}


                {/* <div style={{ marginTop: "25px" }} className="subtitle">
                  開發經驗
                </div>
                <p>1年 以上軟體開發經驗</p>
                <p>2個 部落格網站專案</p>
                <p>1個 後端API伺服器開發</p> */}

                <div style={{ ...R_Display("column", "center", "center"), height: "60px", marginTop: "10px", color: "gray", fontSize: ".8em" }}>
                  展開介紹
                  <div
                    style={{ ...R_Display("column", "center", "center"), marginTop: "50px" }}
                    onClick={() => {
                      set(!open)
                      setTimeout(() => {
                        scrollToElement("skill_focus")
                      }, 100)
                    }}
                  >
                    <Arrow_direction icon={undefined} deration_ID={""} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <animated.div className={"Profile_card_expand"} style={{ ...props, marginBottom: "20px" }}>
            <p className="Skill_title">前端軟體工程</p>
            <div style={{ ...R_Display_bata().display_row, flexWrap: "wrap", height: "auto", alignItems: "flex-end" }}>
              {trail.map((props, index) => (
                <>
                  <animated.div style={props}>
                    <p style={{ ...R_Display_bata().display_colum, alignItems: "center", minWidth: "50px" }}>{data[index]}</p>
                  </animated.div>
                </>
              ))}
            </div>

            <p className="Skill_title">後端軟體工程</p>
            <div style={{ ...R_Display_bata().display_row, flexWrap: "wrap", height: "auto", alignItems: "flex-end" }}>
              {trail.map((props, index) => (
                <>
                  <animated.div id="skill_focus" style={props}>
                    <p style={{ ...R_Display_bata().display_colum, alignItems: "center", minWidth: "50px" }}>{data2[index]}</p>
                  </animated.div>
                </>
              ))}
            </div>

            <p className="Skill_title">佈署/伺服器</p>
            <div style={{ ...R_Display_bata().display_row, flexWrap: "wrap", height: "auto", alignItems: "flex-end" }}>
              {trail.map((props, index) => (
                <>
                  <animated.div id="skill_focus" style={props}>
                    <p style={{ ...R_Display_bata().display_colum, alignItems: "center", minWidth: "50px" }}>{data3[index]}</p>
                  </animated.div>
                </>
              ))}
            </div>
          </animated.div>
        </div>
      </ThemeProvider>

    </>
  )
}

class dataClass {
  data = [
    <>
      <FontAwesomeIcon size="2xl" icon={icon({ name: "react", family: "classic", style: "brands" })} />
      React
    </>,
    <>
      <FontAwesomeIcon size="2xl" icon={icon({ name: "js", family: "classic", style: "brands" })} />
      JS
    </>,
    <>
      <img style={{ width: "29px", height: "29px" }} src={"./../@R036_images/icon/typescript.png"} />
      TS
    </>,
    <>
      <FontAwesomeIcon size="2xl" icon={icon({ name: "html5", family: "classic", style: "brands" })} />
      HTML
    </>,

    <>
      <FontAwesomeIcon size="2xl" icon={icon({ name: "css3-alt", family: "classic", style: "brands" })} />
      CSS
    </>,
  ]

  data2 = [

    <>
      <img style={{ width: "29px", height: "29px" }} src={"./../@R036_images/icon/nodejs_black.svg"} />
      Express.js
    </>,
    <>
      <FontAwesomeIcon size="2xl" icon={icon({ name: "database", family: "classic", style: "solid" })} />
      MySQL
    </>,
    <>
      <img style={{ width: "40px", height: "40px" }} src={"./../@R036_images/icon/redis-svgrepo-com.svg"} />
      Redis
    </>
  ]
  data3 = [
    <>
      <img style={{ width: "25px", height: "25px" }} src={"./../@R036_images/icon/server.png"} />
      NAS
    </>,
    <>
      <img style={{ width: "25px", height: "25px" }} src={"./../@R036_images/icon/docker.png"} />
      Docker
    </>,
    <>
      <FontAwesomeIcon size="2xl" icon={icon({ name: "git-alt", family: "classic", style: "brands" })} />
      Git
    </>,
    <>
      <img style={{ width: "25px", height: "25px" }} src={"./../@R036_images/icon/vercel-icon-svgrepo-com.svg"} />
      Vercel
    </>,

  ]
}
