import React, { useEffect, useState } from "react"
import "./systemDesignHome.css"
import { R_Display } from "../../@R036/R_Style/R_Style"
import BlogTitle from "../../@R036/R_Base/BlogTitle/BlogTitle"
import SimpleTransitionsSlide from "../../@R036/R_Mui/TransitionsSlide"
import DrawerList from "../../Component/drawerList"
import ResponsiveAppBar from "../../@R036/R_Mui/AppBar"
import Button_two from "../../@R036/R_Base/Button/button_two"
import Backdrop from "../../@R036/R_Base/backdrop/backdropBase"
import { SystemDesignAPI } from "../../ServerAPI/systemDesignAPI"
import { Accordion, AccordionDetails, AccordionSummary, Card, CardActionArea, CardContent, CardMedia, createTheme, ThemeProvider, Typography } from "@mui/material"
import Footer from "../../@R036/R_Base/Footer/Footer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import BackspaceIcon from '@mui/icons-material/Backspace';
import LoadingPage from "../home/loadingPage"
import ReactMarkdown from "react-markdown"
import Markdown from "react-markdown"



export default function SystemDesignHome() {

  const [isMenu, set_isMenu] = useState(false)
  const [isZoom, set_isZoom] = useState<{ is: boolean, index: number }>({ is: false, index: 0 })

  const [systemDesignList, setSystemDesignList] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState(true); // 加載狀態
  const [hasError, setHasError] = useState(false); // 錯誤狀態

  const systemDesignAPI = new SystemDesignAPI();

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await systemDesignAPI.Get();

        // 檢查資料是否為空
        if (data && data.length > 0) {
          setSystemDesignList(data[0]); // 假設資料是數組，並取第一個元素
        } else {
          console.warn('收到空或無效的資料');
        }
      } catch (error) {
        console.error('取得資料時發生錯誤:', error);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    loadData(); // 模擬載入
  }, []);


  const handleContextMenu = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  const theme = createTheme({
    components: {

      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: "Noto Sans TC",
            fontSize: "1.1rem"
          }
        }
      }
      , MuiPaper: {
        styleOverrides: {
          root: {
            // borderRadius: "5px"
          }
        }
      }
    },
  });

  // 点击处理函数
  const handleCardClick = (index: any) => {
    set_isZoom({ is: true, index: index });
    // setClickedIndex(index);
    console.log("Clicked card index:", isZoom);
  };
  // const [isLoading, set_isLoading] = React.useState(false)
  const formatMarkdown = (content: string) => {
    // 在每個標題後面添加換行
    let formatted = content?.replace(/(#+\s*[^#]+)/g, '$1\n');

    // 確保每段之間有空行
    formatted = formatted?.replace(/\n{2,}/g, '\n\n');

    return formatted;
};

const markdownContent = formatMarkdown(systemDesignList[isZoom.index]?.content);

// 輸出處理後的 Markdown 內容
console.log(markdownContent);
  return (
    <div className="systemDesign">
      {isLoading ? <LoadingPage /> : <></>}
      <ResponsiveAppBar
        rightTool={[
          <Button_two
            active={() => {
              set_isMenu(!isMenu)
            }}
            isOpen={isMenu}
          />,
        ]}
        style={{ boxShadow: "none" }}
        Logo={<BlogTitle use_my_logo={true} style={{ height: "30px" }} color={"white"} name={"系統學設計"} />}
        Opend={() => set_isMenu(!isMenu)}
        color_Model={"white"}
      >
        <div className="">
          <SimpleTransitionsSlide touch timeout={[1000, 400, 250]} transition={"left"} isActive={isMenu} position_row={"end"} position_column={"start"}>
            <div style={{ zIndex: 5001, height: "100vh" }}>
              <DrawerList
                active={() => {
                  set_isMenu(!isMenu)
                }}
              />
            </div>
          </SimpleTransitionsSlide>

          <Backdrop
            open={isMenu}
            set_open={function (): void {
              set_isMenu(!isMenu)
            }}
          ></Backdrop>
        </div>
      </ResponsiveAppBar>

      <div style={{ color: "white", padding: "20px", paddingTop: "140px", minHeight: "100vh" }}>

        {/* 最上層 */}
        <div style={{ ...R_Display("row", "center", "center"), width: "100%", gap: "50px", flexWrap: 'wrap', userSelect: "none", }}>
          <div style={{ fontSize: "30px", color: "white" }}></div>
          <ThemeProvider theme={theme}>


            {/* 放大展開 */}
            {
              <Backdrop
                open={isZoom?.is}
                set_open={function (): void {
                  // set_isZoom({is:!isZoom?.is,index:1})
                  set_isZoom({
                    ...isZoom,
                    is: !isZoom.is
                  })
                }}
              >
                <div onClick={() => { set_isZoom({ ...isZoom, is: !isZoom.is }) }} style={{ pointerEvents: "auto", position: "absolute", top: 0, right: 0, margin: "30px", zIndex: 6000 }}>
                  <BackspaceIcon fontSize="large" />
                </div>
                <div style={{ boxSizing: "border-box", ...R_Display("column", "center", "center"), width: "100vw", height: "100vh", padding: "20px"}}>
                  {/* 圖片欄位 */}
                  <img style={{ pointerEvents: "auto", width: "100%", maxWidth: "600px", userSelect: "none" }} src={systemDesignList[isZoom.index]?.image} alt="Instagram Image" />
                  {/* 資訊欄位 */}
                  <Accordion sx={{ pointerEvents: "auto", width: "100%", maxWidth: "600px", overflow: "auto"  }}>
                    <AccordionSummary
                      expandIcon={<FontAwesomeIcon icon={icon({ name: "angle-down", family: "classic", style: "solid" })} />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography>{systemDesignList[isZoom.index]?.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                          <Markdown children={systemDesignList[isZoom.index]?.content}/>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Backdrop>
            }

            {/* 圖片內層 */}
            <div style={{ ...R_Display("row", "center", "center"), width: "100%", gap: "50px", flexWrap: 'wrap', userSelect: "none" }}>
              {
                systemDesignList.map((item, index) => (

                  <Card key={index} sx={{ maxWidth: 345, backgroundColor: "" }}>
                    <CardActionArea onClick={() => {
                      handleCardClick(index)
                      console.log("asdasdsa")
                    }}>
                      <CardMedia
                        onContextMenu={handleContextMenu}
                        component="img"
                        height="300"
                        width="300"
                        image={item.image}
                        sx={{
                          objectFit: 'cover',
                          width: '100%',
                          height: 'auto',
                        }}
                        alt="軟體生活 v0.3.6 是一個理念象徵，一個專注在資訊科技與人文的地方，在這個資訊量膨脹的時代，希望能透過深刻的省思與洞察，使資訊富有人文意義"
                      />
                      {/* <CardContent style={{maxHeight:"100px",minHeight:"100px"}}> */}
                      <CardContent style={{ minHeight: "50px" }}>
                        <Typography >
                          {item.title}
                        </Typography>
                        <Typography sx={{
                          width: '100%',
                          fontSize: ".8rem",
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }} variant="body2" color="text.secondary">
                          {item.content}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  // <img style={{ color: "white", width: "300px", height: "300px", outline: "none" }} src={item} alt="Instagram Image" />
                ))
              }

            </div>
          </ThemeProvider>
        </div>
      </div>
      <Footer style={{ marginTop: "100px" }} />
    </div >
  )
}

const systemDesignContent = [
  {
    title: "Full-Stack",
    content: "全端軟體工程",
  },
  {
    title: "Program",
    content: "物件導向程式設計",
  },
  {
    title: "Design",
    content: "軟體系統設計與美學",
  },
  {
    title: "Design",
    content: "軟體系統設計與美學",
  },
]