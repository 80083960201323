import React, { useEffect } from "react"
import "./loadingPage.css"
import { R_Display } from "../../@R036/R_Style/R_Style"
import BlogTitle from "../../@R036/R_Base/BlogTitle/BlogTitle"
import MyComponent from "../../@R036/R_Spring/loading";

export default function LoadingPage(isLoading: any) {
  useEffect(() => {
    if (isLoading) {
      // 禁用滚动
      document.body.style.overflow = 'hidden';
    } else {
      // 启用滚动
      document.body.style.overflow = 'auto';
    }

    return () => {
      // 确保在组件卸载时恢复滚动
      document.body.style.overflow = 'auto';
    };
  }, [isLoading]);

  if (!isLoading) return null;

  return (
    <div className="loadingPage main One_theme_number" style={{ ...R_Display("column", "center", "center") }}>

      {/* <div style={{width: "50px",margin:"10px" }}>
        <BlogTitle nolink color={"white"} use_my_logo></BlogTitle>
      </div> */}
      <MyComponent open={isLoading}></MyComponent>
      {/* <img style={{ width: "120px", paddingLeft: "20px" }} src="/@R036_images/asset/R036_loading_Lightweight.gif" alt="GIF Example"></img> */}
    </div>
  );
}