import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import BlogTitle from "../../@R036/R_Base/BlogTitle/BlogTitle"
import TemporaryDrawer from "../../@R036/R_Mui/TemporaryDrawer"
import { R_Display_bata } from "../../@R036/R_Style/R_Style"
import "./ProfilePage.css"
import Profile from "./profileCard"
import { useState } from "react"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import InstagramIcon from "@mui/icons-material/Instagram"
import Button_base from "../../@R036/R_Base/Button/button_base"
import DrawerList from "../../Component/drawerList"
import ResponsiveAppBar from "../../@R036/R_Mui/AppBar"
import SimpleTransitionsSlide from "../../@R036/R_Mui/TransitionsSlide"
import Backdrop from "../../@R036/R_Base/backdrop/backdropBase"
import Button_two from "../../@R036/R_Base/Button/button_two"

export default function ProfilePage() {
  const [isMenu, set_isMenu] = useState(false)

  return (
    <div style={{ ...R_Display_bata().all_center }}>
      <ResponsiveAppBar
        rightTool={[
          <Button_two
            active={() => {
              set_isMenu(!isMenu)
            }}
            isOpen={isMenu}
            colorModel="black"
          />,
        ]}
        style={{ boxShadow: "none" }}
        Logo={<BlogTitle use_my_logo={true} style={{ height: "30px" }} color={"black"} name={""} />}
        Opend={() => set_isMenu(!isMenu)}
        color_Model={"black"}
      >
        <>
        <SimpleTransitionsSlide touch timeout={[10001,400,250]} transition={"left"} isActive={isMenu} position_row={"end"} position_column={"start"}>
            <div style={{ zIndex: 5001, height: "100vh" }}>
              <DrawerList
                active={() => {
                  set_isMenu(!isMenu)
                }}
              />
            </div>
          </SimpleTransitionsSlide>
          <Backdrop
            open={isMenu}
            set_open={function (): void {
              set_isMenu(!isMenu)
            }}
          ></Backdrop>
        </>
      </ResponsiveAppBar>
      <div style={{ height: "100%" }} className="profile_one_block">
        {/* <BlogTitle use_my_logo={true} style={{marginTop: "10px"}} color={"black"}  name={"-關於開發者"} /> */}
        <div style={{ height: "100px" }}></div>
        <Profile />
      </div>
    </div>
  )
}
