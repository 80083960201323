import { Link, useRouteError } from "react-router-dom";
import { R_Display, R_Display_bata } from "../../@R036/R_Style/R_Style";
import { Button } from "@mui/material";
import BlogTitle from "../../@R036/R_Base/BlogTitle/BlogTitle";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page" style={{height:"100%"}}>
      <div style={{ ...R_Display("column","center","center"), width: "100%", height: "100vh" }}>
      <div style={{ width: "80px" }}>
        <BlogTitle nolink use_my_logo={true} name={""} color={"black"} />
      </div>
        <h1>連結錯誤</h1>
        <Button color='inherit' variant='outlined'><Link to={"/blog"}>返回首頁</Link></Button>
        <div style={{ position: 'fixed', bottom: 0, margin: "20px" }}>
          <p style={{ fontSize: ".6em", fontWeight: 800 }}>錯誤原因</p>
          <p style={{ fontSize: ".4em" }}>1. 請檢查連結名稱是否正確</p>
          <p style={{ fontSize: ".4em" }}>2. 請關閉Google自動翻譯</p>
        </div>
      </div>
    </div>
  );
}