import PauseCircleIcon from "@mui/icons-material/PauseCircle"
import PlayCircleIcon from "@mui/icons-material/PlayCircle"
import SkipNextIcon from "@mui/icons-material/SkipNext"
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious"
import React, { useCallback, useEffect, useRef, useState } from "react"
import "./player.css"
import { SpotifyPlaylist } from "./playerDataController"
import { LinearProgress } from "@mui/material"

type MusicPlayeProps = {
  audioRef: React.MutableRefObject<HTMLAudioElement>
  SpotifyPlaylist: SpotifyPlaylist
  now_list_id: string
}

export default function MusicPlayer(Props: MusicPlayeProps) {
  const [SpotifyPlaylist, set_SpotifyPlaylist] = useState(Props.SpotifyPlaylist)
  const [audioRef, set_audioRef] = useState<React.MutableRefObject<HTMLAudioElement>>(Props.audioRef)

  const [PlaylistData, set_PlaylistData] = useState<any>([])
  const [nowCounter, set_nowCounter] = useState<number>(SpotifyPlaylist.get_nowCounter())

  const [isLoading, setIsLoading] = useState(false)

  const [isMusicPlaying, set_isMusicPlaying] = useState<string>()

  const savedIsMusicPlaying = sessionStorage.getItem("isMusicPlaying")

  const infoBar = useRef<HTMLDivElement>(null)

  const [currentTime, setCurrentTime] = useState<number>(0)

  // const [music_id, set_music_id] = useState(Props.now_list_id)
  console.log("渲染")

  const fetchData = useCallback(async () => {
    try {
      if (savedIsMusicPlaying) {
        console.log("isMusicPlaying已存在")
      } else {
        sessionStorage.setItem("isMusicPlaying", "false")
      }

      console.log("重製")

      await SpotifyPlaylist.SetSpotifyPlaylist(Props.now_list_id)

      set_PlaylistData(SpotifyPlaylist.GetSpotifyPlaylist())
      set_isMusicPlaying(savedIsMusicPlaying!)
      setIsLoading(true)
      console.log("++++")
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    async function Asdads() {
      await SpotifyPlaylist.SetSpotifyPlaylist(Props.now_list_id)
      set_PlaylistData(SpotifyPlaylist.GetSpotifyPlaylist())
      SpotifyPlaylist.ReLoading(audioRef)
      set_isMusicPlaying("false")
      sessionStorage.setItem("isMusicPlaying", "false")
    }
    if (SpotifyPlaylist.get_now_musict_id() === Props.now_list_id) {
      console.log("相同")
    } else {
      console.log("now_list_id偵測改變")
      Asdads()
    }
    // try {
    //   if (savedIsMusicPlaying) {
    //     console.log("不刷新")
    //   }
    //   SpotifyPlaylist.ReLoading(audioRef)

    // } catch (error) {
    //   console.error("Error fetching data:", error)
    // }
  }, [Props.now_list_id])

  useEffect(() => {
    set_nowCounter(SpotifyPlaylist.get_nowCounter())
    console.log("目前撥放器時間: " + currentTime)
    console.log("目前播放是否" + PlaylistData?.items?.[nowCounter].track.preview_url)
  }, [currentTime])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(audioRef.current.currentTime)
    }, 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  function is_active() {
    return savedIsMusicPlaying === "true" ? "active" : ""
  }

  function play() {
    if (audioRef.current.readyState === 0) {
      console.log("初始仔入")
      audioRef.current.src = PlaylistData?.items?.[nowCounter].track.preview_url || "" // 確保 palyURL 不為 undefined

    }

    console.log(audioRef.current.src)

    try {
      if (isLoading) {
        if (isMusicPlaying === "true") {
          audioRef.current.pause()
          set_isMusicPlaying("false")
          sessionStorage.setItem("isMusicPlaying", "false")
        } else {
          audioRef.current.play()
          set_isMusicPlaying("true")
          sessionStorage.setItem("isMusicPlaying", "true")
        }
      }
    } catch (ex) {
      console.log(ex)
    }
  }
  async function NextSonge() {
    SpotifyPlaylist.set_next_Counter(PlaylistData)
    set_nowCounter(SpotifyPlaylist.get_nowCounter())
    console.log(audioRef.current.readyState >= 1)
    audioRef.current.src = PlaylistData?.items[SpotifyPlaylist.get_nowCounter()].track.preview_url || "" // 確保 palyURL 不為 undefined
    console.log(audioRef.current.readyState >= 1)

    if (isMusicPlaying === "true") {
      await audioRef.current.play().catch((ex) => {
        console.log(ex)
      })
    }
    // else {
    //   audioRef.current.load()
    // }
    SlideToRight()
  }

  async function PreSonge() {
    SpotifyPlaylist.set_pre_Counter(PlaylistData)
    set_nowCounter(SpotifyPlaylist.get_nowCounter())
    audioRef.current.src = PlaylistData?.items[SpotifyPlaylist.get_nowCounter()].track.preview_url || "" // 確保 palyURL 不為 undefined

    if (isMusicPlaying === "true") {
      await audioRef.current.play().catch((ex) => {
        console.log(ex)
      })
      // audioRef.current.play()
    }
    // else {
    //   audioRef.current.load()
    // }
    SlideToLeft()
  }
  // const isPlaying = audioRef.current.currentTime > 0 && !audioRef.current.paused && !audioRef.current.ended
  // && audioRef.current.readyState > audioRef.current.HAVE_CURRENT_DATA;
  return (
    <div id="player">
      <div className={`outerBox ${is_active()}`}>
        <div ref={infoBar} className={`info ${is_active()} One_theme_English`}>
          <div className="album-art" style={{ backgroundImage: `url(${PlaylistData?.items?.[SpotifyPlaylist.get_nowCounter()].track.album.images[0].url})` }}></div>
          <div className="text_box">
            <div className="artist">{PlaylistData?.items?.[SpotifyPlaylist.get_nowCounter()].track.name}</div>
            <div style={{ width: '100px' }}>
              {PlaylistData?.items?.[nowCounter].track.preview_url == undefined ? <LinearProgress color="inherit" /> : <></>}
            </div>
            <div className="name">{PlaylistData?.items?.[SpotifyPlaylist.get_nowCounter()].track.artists[0].name}</div>
            <div className={"fill"}>
              <div className={"fillBar"} style={{ width: (currentTime / audioRef.current.duration) * 100 }} />
            </div>
              {/* <div className="number">{nowCounter + 1 + "/" + PlaylistData?.items?.length}</div> */}
          </div>
        </div>
      </div>
      <div className="control-panel ">
        <div className="controls">
          <div
            className="prev"
            onClick={() => {
              if (isLoading) {
                PreSonge()
                console.log(nowCounter)
              }
            }}
          >
            <SkipPreviousIcon sx={{ fontSize: 30, color: "gray" }} />
          </div>
          <div
            onClick={() => {
              if (isLoading) {
                play()
              }
            }}
            className="play"
            style={{ outline: "none" }}
          >
            {isMusicPlaying === "true" ? <PauseCircleIcon sx={{ fontSize: 35, color: "gray" }} /> : <PlayCircleIcon sx={{ fontSize: 35, color: "gray" }} />}
          </div>
          <div
            className="next"
            onClick={() => {
              if (isLoading) {
                NextSonge()
                console.log(nowCounter)
              }
            }}
          >
            <SkipNextIcon sx={{ fontSize: 30, color: "gray" }} />
          </div>
        </div>
      </div>
    </div>
  )

  function SlideToLeft() {
    infoBar.current?.classList.remove("activeLeft")
    infoBar.current?.classList.add("activeLeft")
    const timeout = setTimeout(() => {
      infoBar.current?.classList.remove("activeLeft")
      clearTimeout(timeout)
    }, 1000)
  }

  function SlideToRight() {
    infoBar.current?.classList.remove("activeRight")
    infoBar.current?.classList.add("activeRight")
    const timeout = setTimeout(() => {
      infoBar.current?.classList.remove("activeRight")
      clearTimeout(timeout)
    }, 1000)
  }
}

{
  /* <input
          className="progress-bar"
          type="range"
          min={0}
          max={audioRef.current.duration}
          step={0.1}
          value={currentTime}
        >
        </input> */
}

{
  /* <div className="progress-bar timeupdate">
          <div className="bar"></div>
        </div> */
}
{
  /* <div className="progress-bar timeupdate">
          <div className="bar"></div>
        </div> */
}

{
  /* <div style={{ width: "300px" }}>

          <animated.div className={"fill"} style={props} />
        </div> */
}
