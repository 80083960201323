import React, { useEffect, useState } from "react"
import BaseCard from "../../@R036/R_Asset/Card/BaseCard"
import Backdrop from '../../@R036/R_Base/backdrop/backdropBase'
import BlogTitle from "../../@R036/R_Base/BlogTitle/BlogTitle"
import Button_two from '../../@R036/R_Base/Button/button_two'
import ResponsiveAppBar from "../../@R036/R_Mui/AppBar"
import SimpleTransitionsSlide from '../../@R036/R_Mui/TransitionsSlide'
import DrawerList from '../../Component/drawerList'
import "./movieRoom.css"
import { R_Display } from "../../@R036/R_Style/R_Style"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import LoadingPage from "../home/loadingPage"
import { ButtonBase, Chip, colors, createTheme, IconButton, InputAdornment, InputBase, outlinedInputClasses, Paper, TextField, Theme, ThemeProvider, useTheme } from "@mui/material"
import SearchIcon from '@mui/icons-material/Search';
import { MovieAPI } from "../../ServerAPI/movieAPI"
import YouTubeEmbed from "./youTubeEmbed"
import { Link, Outlet } from "react-router-dom"
import { useLocation } from 'react-router-dom';
import { ArticleLine } from "../article/article"
import ArticleAPI from "../../ServerAPI/ArticleAPI"
import Slider from "./slider"
export default function MovieRoom() {
  const [isLoading, set_isLoading] = useState(false)



  const [isMenu, set_isMenu] = useState(false)

  const [movieList, set_movieList] = useState(false)
  const [nowData, set_nowData] = useState<Array<movieModel>>([])
  const [mainData, set_mainData] = useState<Array<movieModel>>([])
  const [search, set_search] = useState('');
  const [currentTime, setCurrentTime] = useState(new Date());


  type movieModel = {
    title: string,
    img: string,
    Date: string
  }

  interface DataItem {
    Date: string | number | Date;
  }
  useEffect(() => {
    const simulateLoading = async () => {
      // 在開始 API 請求前設置 isLoading 為 true
      set_isLoading(true);

      try {
        const data = await MovieAPI(); // 假設 MovieAPI 是一個返回數據的函數

        const result = data.sort((a: DataItem, b: DataItem) => {
          const dateA = new Date(a.Date).getTime();
          const dateB = new Date(b.Date).getTime();

          // 若日期無效，默認排到最後
          if (isNaN(dateA)) return 1;
          if (isNaN(dateB)) return -1;

          return dateB - dateA; // 降序排序
        });

        console.log(result)

        set_mainData(result);            // 更新 mainData 狀態
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        // 無論成功或失敗，最後都設置 isLoading 為 false
        set_isLoading(false);
      }
    };

    simulateLoading();  // 執行模擬載入

    // 注意：此處不應該放置 console.log(mainData)
  }, []);


  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentTime(new Date());
  //   }, 1000);

  //   // 清理計時器，避免記憶體洩漏
  //   return () => clearInterval(interval);
  // }, []);

  //filter
  useEffect(() => {
    if (search != "") {
      console.log("有東西")
      const a = mainData.filter(item =>
        item.title.toLowerCase().includes(search.toLowerCase())
      );
      set_nowData(a)
    } else {
      console.log("沒東西")
      set_nowData(mainData)
    }
  }, [search, mainData]);

  const [showMainContent, setShowMainContent] = useState(true);
  const location = useLocation(); // 使用 useLocation 鉤子來獲取路徑
  useEffect(() => {
    // 當路徑為 /movieRoom/all 時隱藏主區塊
    if (location.pathname === "/movieRoom/all") {
      setShowMainContent(false);
    } else {
      setShowMainContent(true);
    }
  }, [location.pathname]);

  const customTheme = (outerTheme: Theme) =>
    createTheme({
      palette: {
        mode: outerTheme.palette.mode,
      },
      components: {
        MuiTextField: {
          styleOverrides: {
            root: {
              '--TextField-brandBorderColor': '#E0E3E7',
              '--TextField-brandBorderHoverColor': '#B2BAC2',
              '--TextField-brandBorderFocusedColor': '#6F7E8C',
              '& label.Mui-focused': {
                color: 'var(--TextField-brandBorderFocusedColor)',
              },
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              borderColor: 'var(--TextField-brandBorderColor)',
            },
            root: {
              [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: 'var(--TextField-brandBorderHoverColor)',
              },
              [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: 'var(--TextField-brandBorderFocusedColor)',
              },
            },
          },
        },
      },
    });

  const outerTheme = useTheme();

  const [isLoadingArticle, set_isLoadingArticle] = useState(true)
  const [fetchArticleAPI, set_fetchArticleAPI] = useState<Array<any>>()
  async function fetchDataApi() {
    try {
      if (fetchArticleAPI == null) {
        console.log("API loading...")

        // const result = await new DataController().GetAPI()
        const articleAPI = new ArticleAPI()
        let result = await articleAPI.Read_Article()
        const filteredData = result.filter((item: { tag: string | string[] }) => item.tag.includes("科幻"));
        // 轉換 date 屬性為 Date 對象並進行排序
        result = filteredData.sort((a: { date: string | number | Date }, b: { date: string | number | Date }) => {
          // 確保 a.date 和 b.date 都是合法的日期字串
          const dateA = new Date(a.date).getTime()
          const dateB = new Date(b.date).getTime()
          return dateB - dateA
        });

        set_fetchArticleAPI(result)
        console.log(result);

        setTimeout(() => {
          set_isLoadingArticle(false)
        }, 300)
      }
    } catch (error) {
      console.error(error)
    }
  }

  //初始載入資料
  useEffect(() => {
    fetchDataApi()
  }, [])
  return (

    <div className="movieRoom">

      {isLoading ? <LoadingPage /> : <></>}

      <ResponsiveAppBar
        rightTool={[
          <Button_two
            active={() => {
              set_isMenu(!isMenu)
            }}
            isOpen={isMenu}
            colorModel="black"
          />,
        ]}
        style={{ boxShadow: "none" }}
        Logo={<BlogTitle use_my_logo={true} style={{ height: "30px" }} color={"black"} name={"科幻影視"} />}
        Opend={() => set_isMenu(!isMenu)}
        color_Model={"black"}
      >
        <div className="">
          <SimpleTransitionsSlide touch timeout={[1000, 400, 250]} transition={"left"} isActive={isMenu} position_row={"end"} position_column={"start"}>
            <div style={{ zIndex: 5001, height: "100vh" }}>
              <DrawerList
                active={() => {
                  set_isMenu(!isMenu)
                }}
              />
            </div>
          </SimpleTransitionsSlide>

          <Backdrop
            open={isMenu}
            set_open={function (): void {
              set_isMenu(!isMenu)
            }}
          ></Backdrop>
        </div>
      </ResponsiveAppBar>


      <div style={{ height: "100px" }}></div>



      <Outlet context={nowData}></Outlet>

      <div className="movieRoomRWD">

        {showMainContent &&
          (<div>
            <ThemeProvider theme={customTheme(outerTheme)}>
              <div style={{ margin: "20px" }}>
                <TextField
                  value={search}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    set_search(event.target.value);
                  }}
                  className="searchBox" color="secondary" id="outlined-basic" label="搜尋一部科幻電影"
                  variant="outlined" />
              </div>
            </ThemeProvider>
            <div style={{ margin: "10px" }}>
              <div className="topAD"></div>
            </div>
            <div style={{ ...R_Display("row", "space-between", "center"), margin: "20px", marginTop: "60px" }}>
              <h3 style={{ margin: 0 }}>影視收錄</h3>
              <Link to={"all"}>瀏覽全部</Link>
            </div>
            <div className="gridBox">
              {
                nowData && nowData.length > 0 ? (
                  nowData!.map((one) => (
                    <div style={{ margin: "15px" }}>
                      <BaseCard isSelected image={one.img} width={130} height={180} />
                      <div className={"One_theme_number"} style={{ fontSize: "14px", color: "gray", marginBlock: "10px" }}>{new Date(one.Date).toLocaleDateString("zh-TW", { year: "numeric", month: "2-digit" })}</div>
                      {/* <p style={{fontSize: "10px" }}>{one.Date}</p> */}
                      <p>{one.title}</p>
                    </div>
                  ))
                ) : (
                  <p>資料尚未載入</p> // 當沒有資料時顯示的訊息
                )
              }
            </div>
          </div>
          )
        }
        <div>

          <div style={{ ...R_Display("row", "space-between", "center"), margin: "20px" }}>
            <h3 style={{ margin: 0 }}>文章專欄</h3>
          </div>
          <ArticleLine date={fetchArticleAPI} isLoading={isLoadingArticle} />
        </div>
      </div>

    </div>
  )
}
