import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import LoginPage from "./LoginPage"

type RouteVerifyHookProps  = {
  element :React.ReactNode
}


export function RouteVerifyHook({element}:RouteVerifyHookProps) {
  const [authenticated, setAuthenticated] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    // 在组件挂载时检查 token
    if (sessionStorage.getItem("token")) {
      setAuthenticated(true)
    } else {
      navigate("/blog/6f0f4816-0ff4-4215-9eb3-2091fa846e9e/login")
    }
  }, []) // 空数组表示仅在组件挂载时运行一次
  return <div>{authenticated ? element : <LoginPage></LoginPage>}</div>
}
