import { type } from 'os';
import './Arrow_direction.css'
import React from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactElement } from 'react';


type Arrow_direction = {
    icon: ReactElement | undefined;
    deration_ID: string
    style?: React.CSSProperties
}
export default function Arrow_direction(Props: Arrow_direction) {

    const scrollToElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <a id='R036_arrow' style={Props.style} className="arrow bounce" onClick={() => scrollToElement(Props.deration_ID)}>
            {Props.icon === undefined ?
                <KeyboardArrowDownIcon />
                :
                Props.icon
            }
        </a>
    )
}