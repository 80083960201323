import { Grid, Pagination, Skeleton } from "@mui/material"
import { useEffect, useState } from "react"
import ArticleCard1 from "../../@R036/R_Asset/Card/ArticleCard1/ArticleCard1"
import BlogTitle from "../../@R036/R_Base/BlogTitle/BlogTitle"
import Button_base from "../../@R036/R_Base/Button/button_base"
import Footer from "../../@R036/R_Base/Footer/Footer"
import { TagFilter } from "../../@R036/R_Base/TagFilter"
import TemporaryDrawer from "../../@R036/R_Mui/TemporaryDrawer"
import { R_Display_bata } from "../../@R036/R_Style/R_Style"
import DrawerListComponet from "../../Component/drawerList"
import ArticleAPI from "../../ServerAPI/ArticleAPI"
import "./article.css"
import ResponsiveAppBar from "../../@R036/R_Mui/AppBar"
import DrawerList from "../../Component/drawerList"
import GoogleAds from "./googleAd"
import Button_two from "../../@R036/R_Base/Button/button_two"
import SimpleTransitionsSlide from "../../@R036/R_Mui/TransitionsSlide"
// import Backdrop from "../../@R036/R_Base/backdrop/backdropBase"
import ArticleCard2 from "../../@R036/R_Asset/Card/ArticleCard2/ArticleCard2"
import Backdrop from "../../@R036/R_Base/backdrop/backdropBase"
export default function Article() {
  //設定篩選Tag
  const TagList = ["全部", "前端", "後端", "資料結構", "系統設計", "筆記"]

  //選擇一頁產生幾個文章欄位
  const itemsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1)
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage

  //初始ArticleAPI
  const [fetchArticleAPI, set_fetchArticleAPI] = useState<Array<any>>()
  //篩選資料
  const [articleFilter, set_articleFilter] = useState([])
  //分割每頁顯示資料
  const [articleList, set_articleList] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  async function fetchDataApi() {
    try {
      if (fetchArticleAPI == null) {
        console.log("API loading...")

        // const result = await new DataController().GetAPI()
        const articleAPI = new ArticleAPI()
        let result = await articleAPI.Read_Article()

        // 轉換 date 屬性為 Date 對象並進行排序
        result = result.sort((a: { date: string | number | Date }, b: { date: string | number | Date }) => {
          // 確保 a.date 和 b.date 都是合法的日期字串
          const dateA = new Date(a.date).getTime()
          const dateB = new Date(b.date).getTime()
          return dateB - dateA
        });

        set_fetchArticleAPI(result)
        console.log(result);
        // console.log(fetchArticleAPI);
        Run_articleFilter(result)

        setTimeout(() => {
          setIsLoading(false)
        }, 300)
      }
    } catch (error) {
      console.error(error)
    }
  }

  //初始載入資料
  useEffect(() => {
    fetchDataApi()
  }, [])

  //將篩選資料分割成每頁資料
  useEffect(() => {
    console.log("Pagination loading...")
    const currentItems = articleFilter.slice(indexOfFirstItem, indexOfLastItem)
    set_articleList(currentItems)
  }, [articleFilter, currentPage])

  //篩選資料
  function Run_articleFilter(data: any) {
    try {
      setIsLoading(true)
      console.log("filter loading...")
      set_articleFilter(data)
      setCurrentPage(1)
      setTimeout(() => {
        setIsLoading(false)
      }, 300)
    } catch (error) {
      console.error(error)
    }
  }
  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setCurrentPage(newPage)
  }
  const [isMenu, set_isMenu] = useState(false)
  const handleClick = () => {
    window.scrollTo(0, 0); // 滾動到頁面頂部
  };
  return (
    <div className="article_one_bloack text_RWD">
      {/* <ResponsiveAppBar rightTool={[<Button_base active={() => set_isMenu(!isMenu)} />]} style={{ boxShadow: "none" }} Logo={<BlogTitle use_my_logo={true} style={{ height: "30px" }} color={"black"} name={" -文章"} />} Opend={() => set_isMenu(!isMenu)} color_Model={"black"}>
        <TemporaryDrawer isOpen={isMenu} onClose={() => set_isMenu(!isMenu)} drawDeriction={"right"}>
          <DrawerList active={() => set_isMenu(!isMenu)} />
        </TemporaryDrawer>
      </ResponsiveAppBar> */}

      <ResponsiveAppBar
        rightTool={[
          <Button_two
            active={() => {
              set_isMenu(!isMenu)
            }}
            isOpen={isMenu}
            colorModel="black"
          />,
        ]}
        style={{ boxShadow: "none" }}
        Logo={<BlogTitle use_my_logo={true} style={{ height: "30px" }} color={"black"} name={"技術文章"} />}
        Opend={() => set_isMenu(!isMenu)}
        color_Model={"black"}
      >
        <>
          <SimpleTransitionsSlide touch timeout={[1000, 400, 250]} transition={"left"} isActive={isMenu} position_row={"end"} position_column={"start"}>
            <div style={{ zIndex: 5001, height: "100vh" }}>
              <DrawerList
                active={() => {
                  set_isMenu(!isMenu)
                }}
              />
            </div>
          </SimpleTransitionsSlide>
          <Backdrop
            open={isMenu}
            set_open={function (): void {
              set_isMenu(!isMenu)
            }}
          ></Backdrop>
        </>
      </ResponsiveAppBar>
      <div style={{ minHeight: "100%" }}>
        <div style={{ height: "100px" }} />
        {/* <div style={{ backgroundColor: "wheat" }}>
          網站內文版面開發-測試版
        </div> */}

        <div style={{ maxWidth: "5100px", height: "100%" }}>
          <TagFilter DataState={fetchArticleAPI ?? []} Onchange={Run_articleFilter} TagList={TagList} />
          <div className="RWD_Container">{<ArticleLine date={articleList} isLoading={isLoading} />}</div>
          <div style={{ ...R_Display_bata().colum_center, marginBlock: "25px" }}>
            {/* 原生MUI */}
            <Pagination onClick={handleClick} count={Math.ceil(articleFilter.length / itemsPerPage)} shape="rounded" page={currentPage} onChange={handleChangePage} />
          </div>
        </div>


        <div style={{ height: "40px" }} />
      </div>
      <Footer />
    </div>
  )
}

type ArticleLine = {
  date?: any[]
  isLoading: boolean
}

export function ArticleLine(Props: ArticleLine): React.ReactElement {
  return (
    <Grid container>
      {Props.isLoading
        ? ["", "", "", "", "", ""].map((n, index) => (
          <Grid item xs={12} sm={12} md={12} key={index} style={{ ...R_Display_bata().all_center, marginBlock: "10px", height: "auto" }}>
            <Skeleton key={index} variant="rounded" width={"100%"} height={120} style={{ borderRadius: "10px", marginBottom: 35, maxWidth: "380px" }}></Skeleton>
          </Grid>
        ))
        : Props.date!.map((n, index) => (
          <Grid item xs={12} sm={12} md={12} key={index} style={{ ...R_Display_bata().all_center, marginBlock: "10px", height: "auto" }}>
            <ArticleCard2 key={index} style={{ maxWidth: "380px" }} id={n.id} title={n.title} date={n.date} tag={n.tag} url={n.url} img={n.img}></ArticleCard2>
          </Grid>
        ))}
    </Grid>
  )
}
