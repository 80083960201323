import React from "react"
import DrawerList from "../@R036/R_Base/Drawer/DrawerList"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import InstagramIcon from "@mui/icons-material/Instagram"
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion'
import { Button } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import Button_base from "../@R036/R_Base/Button/button_base"

type Props={
  active:Function
}
export default function DrawerListComponet(props:Props) {
  return (
      <DrawerList
      // topbar={<Button_base active={() => props.active() } close_model ></Button_base>}
        list={[
          { title: "首頁", scr: "/blog", icon: <FontAwesomeIcon icon={icon({ name: "house", family: "classic", style: "solid" })} /> },
          { title: "技術文章", scr: "/article", icon:<AutoAwesomeMotionIcon /> },
          { title: "系統設計", scr: "/systemDesign", icon: <FontAwesomeIcon icon={icon({ name: "diagram-project", family: "classic", style: "solid" })} /> },
          { title: "科幻影視", scr: "/movieRoom", icon: <FontAwesomeIcon size="lg" icon={icon({ name: "film", family: "classic", style: "solid" })} /> },
          // { title: "關於開發者", scr: "/profile", icon: <FontAwesomeIcon icon={icon({ name: "user", family: "classic", style: "solid" })} /> },
          // { title: "軟體社群", scr: "https://www.instagram.com/art.code.design/", icon: <InstagramIcon /> },
          // { title: "技術支援", scr: "/technical", icon: <FontAwesomeIcon icon={icon({ name: "ban", family: "classic", style: "solid" })} /> },         
          
        ]}
      />
  )
}
