import React, { useCallback, useEffect, useRef, useState } from "react"
import { SpotifyAPI } from "../../ServerAPI/SpotifyAPI"
interface DataItem {
  items: any[] // 假设 items 是一个数组
  // 可以根据实际情况添加其他属性
}
const audioRef: any = new Audio("")

export interface SpotifyPlaylist {
  SetSpotifyPlaylist: Function
  GetSpotifyPlaylist: Function
  set_next_Counter: (data: DataItem) => number
  set_pre_Counter: (data: DataItem) => number
  set_nowCounter: Function
  get_nowCounter: Function
  get_audioRef: () => HTMLAudioElement

  get_now_musict_id: Function
  set_now_musict_id: Function
  ReLoading: Function
}

// "47MGQkF1JodqkYltWkWC4d"

export default class playerDataController {
  now_counter: number = 0
  data: any
  musict_id: string | null = null

  get_now_musict_id() {
    return this.musict_id
  }
  set_now_musict_id(music_id: string) {
    return (this.musict_id = music_id)
  }

  async SetSpotifyPlaylist(music_id: string) {
    this.set_now_musict_id(music_id)
    const dataController = new SpotifyAPI()
    this.data = await dataController.GetSpotifyPlaylist(music_id)
    console.log(this.data)

    // audioRef.current.src = this.data.items[this.now_counter].track.preview_url
    // audioRef.current.load()
    // this.get_audioRef().current.load()
    // audioRef.current.src = this.data.items[this.now_counter].track.preview_url
    // audioRef.current.load()
  }

  ReLoading(audioRef: React.MutableRefObject<HTMLAudioElement>) {
    // this.get_audioRef().current.load()
    this.now_counter = 0
    audioRef.current.src = this.data.items?.[this.now_counter].track.preview_url
    // audioRef.current.load()
  }

  GetSpotifyPlaylist() {
    return this.data
  }

  set_pre_Counter(data: DataItem) {
    // (nowCounter - 1 + PlaylistData.items.length) % PlaylistData.items.length
    const result = (this.now_counter - 1 + data?.items.length) % data?.items.length

    this.set_nowCounter(result)
    console.log(result)
    return result
  }

  set_next_Counter(data: DataItem) {
    const result = (this.now_counter + 1) % data?.items.length
    this.set_nowCounter(result)
    console.log(result)
    return result
  }

  set_nowCounter(x: number) {
    return (this.now_counter = x)
  }

  get_nowCounter() {
    // console.log(this.now_counter)
    return this.now_counter
  }
  get_audioRef() {
    return audioRef
  }

  Linstener(audioRef: React.MutableRefObject<HTMLAudioElement>) {
    const intervalId = setInterval(() => {
      // console.log("Linstener: "+audioRef.current.currentTime)
      // audioRef.current.playbackRate = 5;
      if (audioRef.current.ended) {
        console.log("Linstener: " + audioRef.current.ended)
        console.log("下一首歌")
        this.set_next_Counter(this.data)
        audioRef.current.src = this.data.items[this.now_counter].track.preview_url
        audioRef.current.load()
        audioRef.current.play()
      }
    }, 1000)

    return intervalId
  }
}
