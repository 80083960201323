import React, { CSSProperties, useEffect, useState } from "react"
// import {Mycss} from "./MyCss.css";

//最初Bata版 將拋棄
const all_center: CSSProperties = { display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }
const row_center: CSSProperties = { display: "flex", flexDirection: "row", alignItems: "center" }
const colum_center: CSSProperties = { display: "flex", flexDirection: "row", justifyContent: "center" }
const display_row: CSSProperties = { height: "100%", display: "flex", flexDirection: "row" }
const display_colum: CSSProperties = { display: "flex", flexDirection: "column" }

export function R_Display_bata() {
  const Creator = {
    all_center: all_center,
    row_center: row_center,
    colum_center: colum_center,
    display_row: display_row,
    display_colum: display_colum,
  }
  return Creator
}

//todo 最新版R_Display()
export function R_Display(
  flexDirection: "row" | "column",
  justifyContent?: "flex-start" | "center" | "flex-end" | "space-between" | "space-around",
  alignItems?: "flex-start" | "center" | "flex-end"
  ): CSSProperties {

  let Creator: CSSProperties = {}
  if (flexDirection === "row") {
    Creator = { display: "flex", flexDirection: "row" }
  } else if (flexDirection === "column") {
    Creator = { display: "flex", flexDirection: "column" }
  }

  if (justifyContent === "flex-start") {
    Creator["justifyContent"] = "flex-start"
  } else if (justifyContent === "center") {
    Creator["justifyContent"] = "center"
  } else if (justifyContent === "flex-end") {
    Creator["justifyContent"] = "flex-end"
  }else if (justifyContent === "space-between") {
    Creator["justifyContent"] = "space-between"
  }else if (justifyContent === "space-around") {
    Creator["justifyContent"] = "space-around"
  }

  if (alignItems === "flex-start") {
    Creator["alignItems"] = "flex-start"
  } else if (alignItems === "center") {
    Creator["alignItems"] = "center"
  } else if (alignItems === "flex-end") {
    Creator["alignItems"] = "flex-end"
  }
  return Creator
}

//todo 顏色清單
export function R_Color() {
  const Creator = {
    gray: "#6C6C6C",
    gray2: "#F5F5F5",
    // row_center: row_center,
    // colum_center: colum_center,
    // display_row: display_row,
    // display_colum: display_colum
  }

  return Creator
}

//todo R_Positions()
export function R_Positions(position: "up" | "center" | "bottom") {
  let styleResult: React.CSSProperties = {}

  if (position === "up") {
    styleResult = {
      position: "fixed",
      top: 0,
      right: 0,
      ...colum_center,
    }
  } else if (position === "center") {
    styleResult = {
      position: "absolute",
      top: 0,
      ...all_center,
    }
  } else if (position === "bottom") {
    styleResult = {
      position: "fixed",
      bottom: 0,
      // ...colum_center,
    }
  }

  return styleResult
}

//todo 偵測
// export function RWD_Style(): "phone"|"tablet"|"computer"|undefined {
//   let windowWidth = window.innerWidth;
//   if (windowWidth>1024){
//     return "computer"
//   }else if(windowWidth>768){
//     return "tablet"
//   }else if(windowWidth>0){
//     return "phone"
//   }
// }

 export function Scroll_listen(props: Array<{ state: boolean, set_state: Function, y: number }>) {
  const handleScroll = () => {
    // 获取滚动距离
    const scrollY = window.scrollY
    console.log(scrollY)
    // 设置触发滚动效果的阈值

    props.map(n => {
      //if 避免重複渲染State
      if(n.state === true){
        //當滾動到設定值時開啟
        n.set_state(scrollY > n.y)
      }else if(n.state === false){
        n.set_state(scrollY > n.y)
      }
 
    })
  }
  // 监听滚动事件
  window.addEventListener("scroll", handleScroll)
  // 清理事件监听，避免内存泄漏
  return () => {
    window.removeEventListener("scroll", handleScroll)
  }
}

  export function RWD_Style_Listener(set_xs?:Function,set_md?:Function,set_lg?:Function){
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (set_xs) {
          set_xs(windowWidth < 768);
      }
      if (set_md) {
          set_md(windowWidth > 768);
      }
      if (set_lg) {
          set_lg(windowWidth > 1024);
      }
      console.log("监听窗口大小变化事件:" + windowWidth);
    }

    handleResize()
    // 監聽視窗大小變化事件
    window.addEventListener("resize", handleResize)
    // 清理事件監聽器
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }

// /**
//  * @param RWD "phone" | "tablet" | "computer"
//  * 選擇需要控制的響應式
// */
// export function RWD_Style(props: "phone" | "tablet" | "computer"): boolean {
//   const windowWidth = window.innerWidth
  
//   if (props === "computer" && windowWidth > 1024) {
//     return true
//   }
//   if (props === "tablet" && windowWidth > 768) {
//     return true
//   }
//   if (props === "phone" && windowWidth < 768) {
//     return true
//   }

//   return false
// }
