export async function JWT_Verify() {

  console.log("開始驗證")
  // const response = await fetch("http://localhost:3002/api/login/verify", {
  const response = await fetch(`https://r036-center.vercel.app/api/login/verify`, {
    method: "POST",
    headers: {
      // "Content-Type": "application/json", // Set the correct Content-Type
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  })
  console.log("JWT_Verify驗證結果:" + response.status)
  if (response.status === 401) {
    return false
  }
  const result = await response.json()
  console.log("JWT_Verify驗證結果:" + JSON.stringify(result))
  return result
}