
export class SystemDesignAPI {

  async Get() {
    const data = await fetch(`https://r036-center.vercel.app/api/systemicDesign`, { method: 'GET' })
      .then(response => response.json())
      .then((data) => {
        console.log(data); // 在控制台中顯示 API 回傳的資料
        return data
      })
      .catch(error => {
        console.error('Error:', error);
      });
    return data
  }
}