import { ReactElement, useEffect, useState } from "react"
import "./DrawerLista.css"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, useLocation } from "react-router-dom"
import InstagramIcon from "@mui/icons-material/Instagram"
import { R_Display } from "../../R_Style/R_Style"
import BlogTitle from "../BlogTitle/BlogTitle"

type DrawerListProps = {
  list?: Array<{ title: string; scr: string; icon?: ReactElement }>
  topbar?: ReactElement
}

export default function DrawerList(props: DrawerListProps) {
  const currentURL = window.location.href
  const location = useLocation()


  const splitArray = location.pathname.split("/")
  console.log("當前路徑:" + splitArray[splitArray.length - 1])
  console.log(currentURL.split("/").includes(splitArray[splitArray.length - 1]))
  const [isNowSrc, set_isNowSrc] = useState(false)
  useEffect(() => {

    //     if(isNowSrc)
    // set_isNowSrc


  }, [])
  // const location = useLocation();
  // const splitArray = location.pathname.split("/")
  // console.log("當前路徑"+location.pathname)
  // console.log("當前路徑"+splitArray[splitArray.length - 1])
  // const activeIndex = Props.title_List.findIndex((n)=>(n.scr.includes(splitArray[splitArray.length - 1])))
  return (
    <div id="R_Drawer">
      <div className="left-side">

        <div style={{ ...R_Display("row", "flex-end", "flex-start"), marginBottom: "60px" }}>
          {props.topbar}
        </div>
        {/* <BlogTitle use_my_logo={true} style={{ height: "30px",marginBottom:"30px" }} color={"white"} name={""} /> */}
        <div className="side-title">選單</div>
        <div className="side-menu">
          {props.list?.map((props, index) => (
            <Link key={index} className={props.scr.includes(splitArray[splitArray.length - 1]) ? "isSelect" : ""} to={props.scr}>
              {props.icon}
              {props.title}
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}
