import React from "react"
import ReactDOM from "react-dom/client"
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom"
import "./@R036/R_Style/R_FontTheme.css"
import BackSystem from "./Page/Dashboard/BackSystem"
import LoginPage from "./Page/Dashboard/LoginPage"
import { RouteVerifyHook } from "./Page/Dashboard/RouteVerifyHook"
import Article from "./Page/article/article"
import ArticlePage from "./Page/article/articlePage"
import ErrorPage from "./Page/error/error-page"
import Home from "./Page/home/home"
import MovieRoom from "./Page/movieRoom/movieRoom"
import MovieRoomAll from "./Page/movieRoom/movieRoomAll"
import playerDataController from "./Page/player/playerDataController"
import ProfilePage from "./Page/profile/ProfilePage"
import SystemDesignHome from "./Page/systemDesign/systemDesignHome"
import "./index.css"
import CompanyHome from "./Page/company/company"


//原生撥放器物件
const playerController = new playerDataController()


const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/blog" />,
    errorElement: <ErrorPage />,
  },

  {
    path: "/blog",
    element: <Home playerDataController={playerController} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/article",
    element: <Article />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/article/:type/:id",
    element: <ArticlePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/profile",
    element: <ProfilePage />,
    errorElement: <ErrorPage />,
  },

  {
    path: "/blog/6f0f4816-0ff4-4215-9eb3-2091fa846e9e/login",
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },

  {
    path: "/blog/6f0f4816-0ff4-4215-9eb3-2091fa846e9e/back_system",
    element: (
      <RouteVerifyHook element={<BackSystem />} />
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: "/blog/back_system",
    element: (
      <BackSystem />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "systemDesign",
    element: (
      <SystemDesignHome />
    ),
    // children: [
    //   { path: "display_flex", element: <TechSupportHome/>}
    // ],
    errorElement: <ErrorPage />,
  },
  //Css_page
  {
    path: "/Raylandex",
    element: (
      <CompanyHome />
    ),
    // children: [
    //   { path: "display_flex", element: <TechSupportHome/>}
    // ],
    errorElement: <ErrorPage />,
  },
  {
    path: "/movieRoom",
    element: (
      <MovieRoom />
    ),
    children: [
      { path: "all", element: <MovieRoomAll/>}
    ],
    errorElement: <ErrorPage />,
  },

])

const root = document.getElementById("root") as HTMLElement

//在Root設置屬性: 關閉翻譯功能
root.setAttribute("notranslate", "true")

ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)
