
export class SpotifyAPI {

  async GetSpotifyPlaylist(Props:any) {

    const list_id = Props

    // 47MGQkF1JodqkYltWkWC4d

    const data = await fetch(`https://r036-center.vercel.app/api/spotify/playlist/${list_id}`, { method: 'GET' })
      .then(response => response.json())
      .then((data) => {
        console.log(data); // 在控制台中顯示 API 回傳的資料
        return data
      })
      .catch(error => {
        console.error('Error:', error);
      });
    return data
  }




}