import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PersonPinIcon from "@mui/icons-material/PersonPin"
import { Button, ThemeProvider, ToggleButton, ToggleButtonGroup, createTheme } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import Arrow_direction from "../../@R036/R_Asset/Arrow_direction/Arrow_direction"
import BaseCard from "../../@R036/R_Asset/Card/BaseCard"
import DynamicTextBox from "../../@R036/R_Asset/DynamicTextBox"
import Backdrop from "../../@R036/R_Base/backdrop/backdropBase"
import BlogTitle from "../../@R036/R_Base/BlogTitle/BlogTitle"
import Button_two from "../../@R036/R_Base/Button/button_two"
import Carousel from "../../@R036/R_Base/Carousel/Carousela"
import EventDialogBase from "../../@R036/R_Base/Dialog/EventDialogBase"
import Footer from "../../@R036/R_Base/Footer/Footer"
import ResponsiveAppBar from "../../@R036/R_Mui/AppBar"
import SimpleTransitionsSlide from "../../@R036/R_Mui/TransitionsSlide"
import { RWD_Style_Listener, R_Display, Scroll_listen } from "../../@R036/R_Style/R_Style"
import "../../@R036/R_Style/R_Style.css"
import DrawerList from "../../Component/drawerList"
import MusicPlayer from "../player/player"
import "./home.css"
import LoadingPage from "./loadingPage"
// import "./ttt.css"
import "./borderLineCard.css"
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion'
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { PWA_DownloadEvent } from "./useAddToHomescreenPrompt"
import MyComponent from "../../@R036/R_Spring/loading"
import { Theme } from "../../@R036/R_Mui/Theme"
type Props = {
  playerDataController: any
}

function App(Props: Props) {
  const [R0001SState, setR0001SState] = React.useState(false)
  const [isActive_PWA_Event, set_isActive_PWA_Event] = React.useState(false)
  const [isActive_spotify_info, set_isActive_spotify_info] = React.useState(true)

  const [isLoading, set_isLoading] = React.useState(false)

  useEffect(() => {
    const simulateLoading = async () => {
      // 使用 Promise 包裝 setTimeout
      await new Promise((resolve) => setTimeout(resolve, 3000))
      // 載入完成後將 isLoading 設為 false
      set_isLoading(false)
      sessionStorage.setItem("origin_Loading", "true")

      //執行PWA安裝提示
      if (sessionStorage.getItem("PWA_download_counter") == null) {
        // set_isActive_PWA_Event(false)
        if (Click_PWA_DownloadEvent() !== false) {
          console.log("good")
          setTimeout(() => {
            set_isActive_PWA_Event(!isActive_PWA_Event)
            sessionStorage.setItem("PWA_download_counter", "1")
          }, 1000)
        }
      }
    }

    if (sessionStorage.getItem("origin_Loading") === null) {
      set_isLoading(true)
      simulateLoading() // 模擬載入
    }
  }, []) // 依賴陣列為空，表示只在組件首次渲染時執行


  //TODO 滾動顯示過場特效開啟
  const [dynamicTextState, set_dynamicTextState] = React.useState(false)
  const [isShowSection_1, set_isShowSection_1] = useState(false)
  const [isShowSection_2_1, set_isShowSection_2_1] = useState(false)
  const [isShowSection_2_2, set_isShowSection_2_2] = useState(false)
  const [isShowSection_2, set_isShowSection_2] = useState(false)
  useEffect(() => {
    Scroll_listen([
      { state: dynamicTextState, set_state: set_dynamicTextState, y: 500 },
      { state: isShowSection_1, set_state: set_isShowSection_1, y: 400 },
      { state: isShowSection_2_1, set_state: set_isShowSection_2_1, y: 1000 },
      { state: isShowSection_2, set_state: set_isShowSection_2, y: 1800 },
    ])
  }, []) // 空数组表示只在组件挂载和卸载时执行



  const [SpotifyPlaylist, set_SpotifyPlaylist] = useState(Props.playerDataController)
  const [audioRef, set_audioRef] = useState(useRef(SpotifyPlaylist.get_audioRef()))
  const [music_id, set_music_id] = useState(SpotifyPlaylist.get_now_musict_id() ?? "47MGQkF1JodqkYltWkWC4d")



  useEffect(() => {
    SpotifyPlaylist.Linstener(audioRef)

  }, [])
  console.log("重新渲染")

  const Click_PWA_DownloadEvent = PWA_DownloadEvent()

  const [xs, set_xs] = useState(false)
  const [md, set_md] = useState(false)


  const [isMusicPlaying, set_isMusicPlaying] = useState<string | null>()
  const savedIsMusicPlaying = sessionStorage.getItem("isMusicPlaying")

  useEffect(() => {
    RWD_Style_Listener(set_xs, set_md)
  }, [])

  useEffect(() => {
    set_isMusicPlaying(savedIsMusicPlaying)
  }, [sessionStorage.getItem("isMusicPlaying")])

  const drawlist = [
    { title: "首頁", scr: "#blog" },
    { title: "文章", scr: "/article" },
    { title: "設計", scr: "/systemDesign" },
    { title: "關於", scr: "/profile" },
  ]
  const [isMenu, set_isMenu] = useState(false)

  const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    if (nextView !== null) {
      set_music_id(nextView)
    }
    console.log(nextView)
  }

  

  return (
    <div className="text_RWD home">
      <ResponsiveAppBar
        rightTool={[
          <Button_two
            active={() => {
              set_isMenu(!isMenu)
            }}
            isOpen={isMenu}
          />,
        ]}
        style={{ boxShadow: "none" }}
        list={drawlist}
        Logo={<BlogTitle use_my_logo={true} style={{ height: "30px" }} color={"white"} name={""} />}
        Opend={() => set_isMenu(!isMenu)}
        color_Model={"white"}
      >
        <>
          <SimpleTransitionsSlide touch timeout={[1000, 400, 250]} transition={"left"} isActive={isMenu} position_row={"end"} position_column={"start"}>
            <div style={{ zIndex: 5001, height: "100vh" }}>
              <DrawerList
                active={() => {
                  set_isMenu(!isMenu)
                }}
              />
            </div>
          </SimpleTransitionsSlide>

          <Backdrop
            open={isMenu}
            set_open={function (): void {
              set_isMenu(!isMenu)
            }}
          ></Backdrop>
        </>
      </ResponsiveAppBar>
      {isLoading ? <LoadingPage /> : <></>}

      {/* <LoadingPage /> */}

      <div className="home_one_bloack">
        <div style={{ position: "absolute", top: 0, paddingTop: "100px", textAlign: "center", ...R_Display("row", "center", "center"), paddingInline: "0.5rem", boxSizing: "border-box" }} className="content">

          <div className={`float-in ${dynamicTextState ? "" : "show"}`}>
            {isLoading ? <></> :
              <>
                <DynamicTextBox className="One_theme_number" style={{ color: "white", fontSize: "2.2em", margin: 0 }} text={`研發室036號`} isOpen={dynamicTextState} />
                <DynamicTextBox key={1} className="" style={{ color: "white", fontSize: ".8rem", fontWeight: 300, letterSpacing: "1px" }} text={"「在現代科學的浪潮中，我們的理性根植於人文學科的土壤」"} isOpen={dynamicTextState} />
              </>
            }
            <Link to={"/profile"} style={{ marginTop: "20px" }}>
              <Button onClick={() => { }} variant="outlined" style={{ color: "white", borderColor: "white", marginTop: "20px" }} href="">
                <PersonPinIcon fontSize="small" sx={{ marginRight: "5px" }} />
                關於開發者
              </Button>
            </Link>
          </div>

          <Arrow_direction icon={undefined} deration_ID={"home_two"} style={{ margin: "40px" }} />
        </div>

        <div>{/* MusictBOX */}
          <div
            style={{ paddingInline: "", zIndex: 1000, position: "fixed", bottom: 20, right: 20 }}
            className={`spinner-box ${isMusicPlaying === "true" ? "musicStarting" : "musicPause"}`}
            onClick={() => {
              setR0001SState(!R0001SState)
            }}
          >
            {/* <MusicNoteIcon style={{ position: "absolute", top: 0, zIndex: 1011, height: "100%", color: "white" }} /> */}
            {/* <FontAwesomeIcon icon="fa-brands fa-spotify" /> */}
            <div style={{ position: "absolute", top: "14px", zIndex: 1011, height: "100%", color: "white" }}>
              {/* <FontAwesomeIcon icon="fa-brands fa-spotify" /> */}
              <FontAwesomeIcon size="2xl" color="#1DB954" icon={icon({ name: "spotify", family: "classic", style: "brands" })} />
            </div>
            <div className={` ${isMusicPlaying === "true" ? "glowing-circle" : "glowing-circle1"}`}>
              <div className="circle-core"></div>
            </div>
          </div>
        </div>
        {/* <RightDrawer drawDeriction={"right"}>
          <DrawerList />
        </RightDrawer> */}
      </div>

      {/* 第二區塊 */}
      <div id="home_two" className={`Font_Family_Oswald `} style={{ ...R_Display("column", "center", "center") }}>
        <div style={{ height: "100%" }} className={`float-in ${isShowSection_1 ? "show" : ""}`} >
          <div style={{ maxWidth: "40rem", paddingInline: "20px", paddingBlock: "50px", paddingTop: "100px", boxSizing: "border-box" }} className="Font_Family_NoteSans">
            <h2 className="Font_Family_NoteSans top_title" >
              研發室<span className="One_theme_number">036</span>號|
            </h2>
            <p style={{ fontSize: "18px", color: "rgb(255, 255, 255,1)" }}>是一個理念象徵，一個專注在資訊科技與人文的地方</p>
            <p style={{ fontSize: "16px", color: "rgb(255, 255, 255,0.5)", marginTop: "20px" }}>象徵著不斷更新的版本，在這個數位時代，生活就像軟體一樣，不斷進行更新，每一次經驗、每一個挑戰，都為生活帶來了新的修補與升級</p>
          </div>
          <div className="card">
            <div className="content">
              <div className="back">
                <div className="back-content Font_Family_Schibsted_Grotesk">
                  <img className="okmask" style={{}} src={"/@R036_images/home/R036-Software-Life-Banner.svg"}></img>
                  {/* <img className="btn-shine" style={{}} src={"/@R036_images/main/R036-LOGO(白色).svg"}></img> */}
                  {/* <a style={{ transform: "translateY(13px)", fontSize: "1rem" }} className="btn-shine Font_Family_Oswald">
                    Sofeware Life
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="home_three">
      {/* <img src={"/@R036_images/coding-workshop-animate.svg"} alt="logo" width="100%" height="100%" /> */}
        {/* 3-1區塊 */}
        <div className={`Font_Family_Oswald `} style={{ ...R_Display("column", "center", "center") }}>
          <div style={{ height: "100%" }} className={`float-in ${isShowSection_2_1 ? "show" : ""}`} >
            <div style={{ maxWidth: "40rem", paddingInline: "20px", paddingBlock: "50px", paddingTop: "100px", boxSizing: "border-box" }} className="Font_Family_NoteSans">
              <h2 className="Font_Family_NoteSans top_title">
                技術文章 <span className="One_theme_number"><AutoAwesomeMotionIcon /></span> |
              </h2>
              <p style={{ fontSize: "18px", color: "rgb(255, 255, 255,1)" }}>軟體開發技術寫作</p>
              <p style={{ fontSize: "16px", color: "rgb(255, 255, 255,0.5)", marginTop: "20px" }}>秉持飲水思源的理念，在軟體工程技術精進的同時，也不忘回饋軟體技術圈，並將開發過程中的經驗記錄下來</p>
            </div>
          </div>
        </div>
        {/* 3-2區塊 */}
        <div className={`Font_Family_Oswald `} style={{ ...R_Display("column", "center", "center") }}>
          <div style={{ height: "100%" }} className={`float-in ${isShowSection_2_1 ? "show" : ""}`} >
            <div style={{ maxWidth: "40rem", paddingInline: "20px", paddingBlock: "50px", paddingTop: "100px", boxSizing: "border-box" }} className="Font_Family_NoteSans">
              <h2 className="Font_Family_NoteSans top_title">
                系統學設計 <span className="One_theme_number"> <FontAwesomeIcon size="xs" icon={icon({ name: "diagram-project", family: "classic", style: "solid" })} /></span> |
              </h2>
              <p style={{ fontSize: "18px", color: "rgb(255, 255, 255,1)" }}>運用系統學設計的概念</p>
              <p style={{ fontSize: "16px", color: "rgb(255, 255, 255,0.5)", marginTop: "20px" }}>透過圖像關係來傳達一個思維過程，將不同領域的知識融合，建構一個有用的系統模型</p>
            </div>
          </div>
        </div>

        {/* 3-3區塊 */}
        <div className={`Font_Family_Oswald `} style={{ ...R_Display("column", "center", "center") }}>
          <div style={{ height: "100%" }} className={`float-in ${isShowSection_2_1 ? "show" : ""}`} >
            <div style={{ maxWidth: "40rem", paddingInline: "20px", paddingBlock: "50px", paddingTop: "100px", boxSizing: "border-box" }} className="Font_Family_NoteSans">
              <h2 className="Font_Family_NoteSans top_title">
                科幻影視 <span className="One_theme_number"><FontAwesomeIcon size="xs" icon={icon({ name: "film", family: "classic", style: "solid" })} /></span> |
              </h2>
              <p style={{ fontSize: "18px", color: "rgb(255, 255, 255,1)" }}>致力於推廣科幻文學</p>
              <p style={{ fontSize: "16px", color: "rgb(255, 255, 255,0.5)", marginTop: "20px" }}>並特別設立了科幻專欄網站，我們相信，科技的進步離不開豐富的想像力，而科幻正是激發創新思維、探索未來世界的重要文學形式</p>
            </div>
          </div>
        </div>

      </div>

      {/* 第三區塊 */}
      <div id="web_home_skill" className="" style={{ ...R_Display("column", "flex-start", "center") }}>
        <div className={`float-in ${isShowSection_2 ? "show" : ""}`}>
          <div className="Font_Family_NoteSans" style={{ paddingInline: "20px", paddingTop: "150px", boxSizing: "border-box" }} >
            <h2 className="Font_Family_NoteSans top_title">
              網站開發技術 |
            </h2>
          </div>
        </div>
        <div style={{ width: "100%", height: "100%" }} className={`Carousel_box ${isShowSection_2 ? "show" : ""}`}>
          <div style={{ height: "500px" }}>
            <Carousel content={CarouselList}></Carousel>
          </div>
        </div>
      </div>

      {/* <PWA /> */}
      <SimpleTransitionsSlide touch timeout={[1000, 500, 500]} transition={"up"} isActive={isActive_PWA_Event} position_row={"start"} position_column={"end"}>
        <div style={xs ? { zIndex: 10, width: "100vw", fontWeight: 400, boxShadow: "0 0 0" } : md ? { zIndex: 10, width: "300px", boxSizing: "border-box", fontWeight: 400 } : {}}>
          <EventDialogBase
            sx={xs ? { borderRadius: 0, boxShadow: "0 0 0" } : { margin: "20px" }}
            isActive={isActive_PWA_Event}
            set_isActive={set_isActive_PWA_Event}
            event={() => {
              Click_PWA_DownloadEvent()
            }}
            // text_title="安裝提示"
            text_content={`是否要安裝網頁捷徑到裝置中`}
            text_s_content={`(請使用GoogleChrome來開啟)`}
            Button_ok="哪次不同意"
            Button_cancel="先不要"
          />
        </div>
      </SimpleTransitionsSlide>

      {/* MusicBOX */}
      <Backdrop
        open={R0001SState}
        set_open={function (): void {
          setR0001SState(!R0001SState)
        }}
        backdrop={true}
      >
      <SimpleTransitionsSlide touch timeout={[1000, 500, 500]} transition={"right"} isActive={isActive_spotify_info} position_row={"center"} position_column={"center"}>
        <div style={{zIndex: 10, width: "300px", boxSizing: "border-box", fontWeight: 400}}>
          <EventDialogBase
            sx={xs ? { borderRadius: 0, boxShadow: "0 0 0" } : { margin: "20px" }}
            isActive={isActive_spotify_info}
            set_isActive={set_isActive_spotify_info}
            event={() => {
              // Click_PWA_DownloadEvent()
              set_isActive_spotify_info(false)
            }}
            text_title="公告"
            text_content={`目前已停用Spotify串流音樂宣傳用試聽功能`}
            // Button_cancel="確定"
            Button_ok="確定"
          />
        </div>
      </SimpleTransitionsSlide>
        <div className="MusicBox_rightSideMusicList">
          <SimpleTransitionsSlide touch={true} timeout={[1000, 500, 500]} position_row={"end"} position_column={"center"} transition={"left"} isActive={R0001SState}>
            <ThemeProvider theme={Theme}>
              <ToggleButtonGroup orientation="vertical" value={music_id} exclusive onChange={handleChange}>
                <ToggleButton value="47MGQkF1JodqkYltWkWC4d" aria-label="list">
                  <FontAwesomeIcon size="lg" icon={icon({ name: "mug-hot", family: "classic", style: "solid" })} />
                  <span style={{ marginLeft: "10px" }}>日常節奏</span>
                </ToggleButton>
                <ToggleButton value="0Qi9XdW1aLVAFx87C8nvff" aria-label="module">
                  <FontAwesomeIcon size="lg" icon={icon({ name: "martini-glass", family: "classic", style: "solid" })} />
                  <span style={{ marginLeft: "10px" }}>午夜微醺</span>
                </ToggleButton>
                <ToggleButton value="6BMsjkh7fiTYBQcM5Mkpak" aria-label="quilt">
                  <FontAwesomeIcon size="lg" icon={icon({ name: "chess-knight", family: "classic", style: "solid" })} />
                  <span style={{ marginLeft: "10px" }}>古典時刻</span>
                </ToggleButton>
                <ToggleButton value="2wRGm87noAM7vPFxKLGDS1" aria-label="quilt">
                  <FontAwesomeIcon size="lg" icon={icon({ name: "microchip", family: "classic", style: "solid" })} />
                  <span style={{ marginLeft: "10px" }}>電子叛客</span>
                </ToggleButton>
                <ToggleButton value="0J6tOwRpy4lFpFqnYD5VxI" aria-label="quilt">
                  <FontAwesomeIcon size="lg" icon={icon({ name: "lightbulb", family: "classic", style: "solid" })} />
                  <span style={{ marginLeft: "10px" }}>晨間黎明</span>
                </ToggleButton>
                {/* <ToggleButton value="0mqSVBroFTtL3d5rMbjWH8" aria-label="quilt">
                  <FontAwesomeIcon size="lg" icon={icon({ name: "fire-flame-curved", family: "classic", style: "solid" })} />
                  <span style={{ marginLeft: "10px" }}>史詩-0110</span>
                </ToggleButton> */}
                <ToggleButton value="1MLmDkCJ0w4DrbgVgZheSS" aria-label="quilt">
                  <FontAwesomeIcon size="lg" icon={icon({ name: "radio", family: "classic", style: "solid" })} />
                  <span style={{ marginLeft: "10px" }}>自由藍調</span>
                </ToggleButton>
                <ToggleButton value="7pm65z0O9tWSJEd0cEFkbf" aria-label="quilt">
                  <FontAwesomeIcon size="lg" icon={icon({ name: "seedling", family: "classic", style: "solid" })} />
                  <span style={{ marginLeft: "10px" }}>雨後都會</span>
                </ToggleButton>
              </ToggleButtonGroup>
            </ThemeProvider>
          </SimpleTransitionsSlide>
        </div>

        {/* <SimpleTransitionsSlide touch={false} timeout={[1500, 200, 500]} position_row={"start"} position_column={"center"} transition={"right"} isActive={R0001SState}>
          <div className="" style={{ letterSpacing: "2px", width: "100%", height: "100vh", pointerEvents: "none", lineHeight: 1.2, marginTop: "50px" }}>
            <div className="MusicBox_CenterBox" style={{}}>{Spotifyget_nowCounter}</div>
            <div className="MusicBox_CenterBox" style={{}}>JAZZ</div>
            <div className="MusicBox_CenterBox" style={{}}>FUNK</div>
            <div className="MusicBox_CenterBox" style={{}}>BLUES</div>
            <div className="MusicBox_CenterBox" style={{}}>LO-FI</div>
            <div className="MusicBox_CenterBox" style={{}}>SMILL</div>
          </div>
        </SimpleTransitionsSlide> */}
        <SimpleTransitionsSlide touch timeout={[1000, 500, 500]} position_row={"center"} position_column={"end"} transition={"up"} isActive={R0001SState}>
          <div style={{}}>
            <div style={{ zIndex: 4000, margin: 50 }}>
              <MusicPlayer now_list_id={music_id} audioRef={audioRef} SpotifyPlaylist={SpotifyPlaylist} />
            </div>
          </div>
        </SimpleTransitionsSlide>
      </Backdrop>
      <Footer />
    </div>
  )

}



const CarouselList = [
  {
    Element: <BaseCard image={"/@R036_images/home/artCard.jpg"} />,
    title: "Full-Stack",
    subtitle: "全端軟體工程",
    tag: "React / Javascript / Express.js / Html / CSS ",
  },
  {
    Element: <BaseCard image={"/@R036_images/home/codeCard.jpg"} />,
    title: "Program",
    subtitle: "物件導向程式設計",
    tag: "Javascript / Typescript/ Jave",
  },
  {
    Element: <BaseCard image={"/@R036_images/home/desCard.png"} />,
    title: "Design",
    subtitle: "軟體系統設計與美學",
    tag: "System Design and Design thinking",
  },
]

export default App
