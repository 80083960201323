import { createTheme } from "@mui/material";

export const Theme = createTheme({
    components: {

        MuiChip: {
            styleOverrides: {
                root: {
                    fontFamily: "Noto Sans TC,sans-serif;",
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    fontFamily: "Monomaniac One, sans-serif;"
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: "Noto Sans TC,sans-serif;"
                }
            }
        },

        MuiToggleButton: {
            styleOverrides: {
                root: {
                    color: "white",
                    borderBottom: "2px solid #ffffff", // 下邊框
                    justifyContent: "start",
                    "&.Mui-selected": {
                        color: "black",
                        backgroundColor: "white",
                        "&:hover": {
                            backgroundColor: "white",
                        },
                    },
                },
            },
        },
    },
});

