import React, { useState } from 'react'
import { useSpring, animated } from '@react-spring/web'
import './loading.css'
import BlogTitle from '../R_Base/BlogTitle/BlogTitle'
import { R_Display } from '../R_Style/R_Style'


type MyLoadingProps ={
    open : boolean
}

export default function MyLoading(props:MyLoadingProps) {
    const [open, toggle] = useState(false)
    //   const [ref, { width }] = useMeasure


    const spring = useSpring({
        from: { width: "0%" },
        to: { width: props.open ? "100%" : "0%" },
        config: {
            mass: 2,
            friction: 10,
            tension: 10,
        },

    })

    return (
        <div className={"loadingBar"}>

            {/* <div style={{ height: "100px", width: "100px",...R_Display("row", "center", "center") }}>
                <div style={{ height: "100%", width: "100%"}}>
                    <BlogTitle nolink color={"white"} use_my_logo></BlogTitle>
                </div>
                <animated.div className={"text"}>{spring.width.to((x: any) => x.replace(/(\d+)\.\d+%/, '$1%'))}</animated.div>
            </div> */}
                <animated.div className={"text"}>{spring.width.to((x: any) => x.replace(/(\d+)\.\d+%/, '$1%'))}</animated.div>

            {/* <div className={"content"} onClick={() => toggle(!open)}>
                <animated.div className={"fill"} style={spring} />
            </div> */}
        </div>
    )
}

