import React, { useState } from "react"
import ResponsiveAppBar from "../../@R036/R_Mui/AppBar"
import Button_two from "../../@R036/R_Base/Button/button_two"
import BlogTitle from "../../@R036/R_Base/BlogTitle/BlogTitle"
import SimpleTransitionsSlide from "../../@R036/R_Mui/TransitionsSlide"
import DrawerList from "../../Component/drawerList"
import Backdrop from "../../@R036/R_Base/backdrop/backdropBase"
import BaseCard from "../../@R036/R_Asset/Card/BaseCard"
import { Link, useOutletContext } from "react-router-dom"
import { Button } from "@mui/material"

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type movieModel = {
    title: string,
    img: string
    Date: string
}

type movieRoomAll_Props = {
    data:Array<movieModel>
}

export default function MovieRoomAll() {

    const [isMenu, set_isMenu] = useState(false)
    const [nowData, set_nowData] = useState<Array<movieModel>>([])

    const data:Array<movieModel> = useOutletContext();
    return (<>
        <ResponsiveAppBar
            rightTool={[
                <Button_two
                    active={() => {
                        set_isMenu(!isMenu)
                    }}
                    isOpen={isMenu}
                    colorModel="black"
                />,
            ]}
            style={{ boxShadow: "none" }}
            Logo={<BlogTitle use_my_logo={true} style={{ height: "30px" }} color={"black"} name={""} />}
            Opend={() => set_isMenu(!isMenu)}
            color_Model={"black"}
        >
            <div className="">
                <SimpleTransitionsSlide touch timeout={[1000, 400, 250]} transition={"left"} isActive={isMenu} position_row={"end"} position_column={"start"}>
                    <div style={{ zIndex: 5001, height: "100vh" }}>
                        <DrawerList
                            active={() => {
                                set_isMenu(!isMenu)
                            }}
                        />
                    </div>
                </SimpleTransitionsSlide>

                <Backdrop
                    open={isMenu}
                    set_open={function (): void {
                        set_isMenu(!isMenu)
                    }}
                ></Backdrop>
            </div>
        </ResponsiveAppBar>
            {/* <ThemeProvider theme={customTheme(outerTheme)}>
        <div style={{ margin: "20px" }}>
          <TextField
            value={search}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              set_search(event.target.value);
            }}
            className="searchBox" color="secondary" id="outlined-basic" label="搜尋一部科幻電影"
            variant="outlined" />
        </div>
      </ThemeProvider> */}
        <Link style={{color:"black"}} to={'/movieRoom'}><Button color='inherit'><ArrowBackIcon /><span style={{ fontFamily: "Noto Sans TC, sans-serif" }}>返回</span></Button></Link>

        <div className="gridBoxAll">
            {data?.map((one) => (
                <div style={{ margin: "15px" }}>
                    <BaseCard image={one.img} width={130} height={180} />
                    <div className={"One_theme_number"} style={{ fontSize: "14px", color: "gray", marginBlock: "10px" }}>{new Date(one.Date).toLocaleDateString("zh-TW", { year: "numeric", month: "2-digit" })}</div>
                    <p style={{ marginBlock: "15px" }}>{one.title}</p>
                </div>
            ))}
        </div>
    </>)


}