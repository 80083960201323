import React from "react"

export type articleDataModel = {
  code: string
  title: string
  date: Date
  tag: any
  img: string
  url: string
}

export default class ArticleAPI {


  dataModeaaal: articleDataModel = {
    code: "",
    title: "",
    date: new Date(),
    tag: [],
    img: "",
    url: "",
  }

  async Read_articleFiles(type: string, id: string) {
    try {
      // 構造文件路徑。注意，public 文件夾內的文件在 React 中可以通過相對 URL 獲取
      const path = `/@R036_article/${type}/${id}/R036_article_index.md`;

      // 使用 fetch 來獲取靜態資源文件
      const response = await fetch(path);

      if (!response.ok) {
        throw new Error(`Failed to fetch file. Status: ${response.status}`);
      }

      // 獲取文件的文本內容
      const data = await response.text();
      console.log(data);

      return data;
    } catch (err) {
      console.error('Error reading file:', err);
    }
  }



  async Read_articlePage(type: string, id: string) {
    try {
      const response = await fetch(`http://localhost:3002/api/article/${type}/${id}`, {
        // const response = await fetch(`https://r036-center.vercel.app/api/article/${type}/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json", // Set the correct Content-Type
          // Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      if (!response.ok) {
        throw new Error(`Failed to delete article. Status: ${response.status}`)
      }

      const result = await response.text()
      console.log(result)
      return result
    } catch (error) {
      console.log(error)
    }
  }

  async Read_Article() {
    try {
      // const response = await fetch("http://localhost:3002/api/article", {
      const response = await fetch("https://r036-center.vercel.app/api/article", {
        method: "GET",
        headers: {
          "Content-Type": "application/json", // Set the correct Content-Type
          // Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      if (!response.ok) {
        throw new Error(`Failed to delete article. Status: ${response.status}`)
      }

      const result = await response.json()
      console.log(result)
      return result
    } catch (error) {
      console.log(error)
    }
  }

  async Create_Article(data: object) {
    // await fetch("http://localhost:3002/api/article", {
    await fetch("https://r036-center.vercel.app/api/article", {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Set the correct Content-Type
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(data), // Send JSON data
    })
  }

  async Update_Article(guid: string, data: articleDataModel) {
    try {
      // await fetch(`http://localhost:3002/api/article/${guid}`, {
      await fetch(`https://r036-center.vercel.app/api/article/${guid}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json", // Set the correct Content-Type
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify(data), // Send JSON data
      })
    } catch (error: any) {
      console.error("Error Update article:", error.message)
    }
  }

  async Delete_Article(guid: string) {
    // console.log("成功接收"+guid)
    try {
      // await fetch(`http://localhost:3002/api/article/${guid}`, {
      await fetch(`https://r036-center.vercel.app/api/article/${guid}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
    } catch (error: any) {
      console.error("Error deleting article:", error.message)
    }
  }
}
