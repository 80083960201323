import MenuIcon from "@mui/icons-material/Menu"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
//rutor
import { Hidden } from "@mui/material"
import TopBar from "../R_Base/TopbarBase/TopBar"
import React, { useEffect, useState } from "react"
import { RWD_Style_Listener, Scroll_listen } from "../R_Style/R_Style"
import Button_base from "../R_Base/Button/button_base"

type Props = {
  //傳入一個 [] string 來改變上方欄位
  Logo: React.ReactElement
  settings?: Array<{
    title: string
    method: Function
  }>

  list?: Array<{
    scr: string
    title: string
  }>

  isOpend?: boolean
  Opend: Function
  style?: React.CSSProperties
  children?: React.ReactElement
  rightTool?: Array<object>
  color_Model: "black" | "white"
}

/**
 *
 * @param props
 *
 * @returns  aa
 */
function ResponsiveAppBar(props: Props) {
  const [backgroundcolor, set_backgroundcolor] = useState("")
  const [nowScroll, set_nowScroll] = useState<number>()

  useEffect(() => {
    function change_blackgroundColor() {
      let test_item = window.scrollY
      let color_dot = test_item / 800 // 根据需要调整变化速度
      if (color_dot > 0.5) {
        color_dot = 0.5
      }
      set_backgroundcolor(`rgb(66, 66, 66, ${color_dot})`)
    }
    change_blackgroundColor()
    // 監聽視窗大小變化事件
    window.addEventListener("scroll", change_blackgroundColor)
    // 清理事件監聽器
    return () => {
      window.removeEventListener("scroll", change_blackgroundColor)
    }
  }, [])

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    props.Opend()
    console.log("按鈕典籍")
  }

  const TopBarColor = props.color_Model === "black" ? "#3C3C3C" : "#FFFFFF"
  return (
    <AppBar position="absolute" color="transparent" sx={{ zIndex: 1000, padding: "0.5rem", ...props.style }}>
      <Toolbar variant={"dense"} style={{ marginBlock: "10px" }}>

        {/* 1. LOGO */}
        <Box sx={{ position: "absolute",zIndex:5002 }}>{props.Logo}</Box>

        {/* 2 xxx */}
        {props.children}

        {/* 3. CenterBar */}
        <Box key={1} sx={{ zIndex: 100, flex: 1, display: { xs: "none", md: "flex" }, justifyContent: "center", paddingTop: "15px" }}>
          <TopBar title_List={props.list ?? []} style={{ fontSize: "16px", backgroundColor: "transparent", color: props.color_Model }} color={TopBarColor} color_Model={props!.color_Model}></TopBar>
        </Box>


        {/* 4. RightTool-Mobil */}
        <Box sx={{ flex: 1, display: { xs: "flex", md: "none" }, justifyContent: "flex-end" ,zIndex:5001}}>
          {/* <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="default">
            <MenuIcon />
          </IconButton> */}
          {/* <Button_base active={(even: any) => handleOpenNavMenu(even)}  /> */}
          {props.rightTool?.map((n,index) => (
            <div key={index}>
              <>{n}</>
            </div>
          ))}
        </Box>

        {/* 4. RightTool-PC */}
        <Box sx={{ display: { xs: "none", md: "flex" } ,zIndex:5001}}>
          <Hidden mdDown>
            {props.rightTool?.map((n, index) => (
              <div key={index}>
                <>{n}</>
              </div>
            ))}
          </Hidden>
          {/* <Hidden mdDown>
            <Box sx={{ minWidth: "120px", position: "relative", right: 0, textAlign: "right" }}></Box>
          </Hidden> */}
        </Box>

        {/* 頭像 */}
        {/* <Hidden mdDown>
          <Box sx={{ minWidth: "120px", position: "relative", right: 0, textAlign: "right" }}>
            <IconButton onClick={handleOpenUserMenu} sx={{ width:"auto"}}>
                <Avatar alt="Remy Sharp" src="/@R036_images/main/R036_isWho(v2).jpg" />
              </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Hidden> */}
      </Toolbar>
    </AppBar>
  )
}
export default ResponsiveAppBar
