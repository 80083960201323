import Slide from "@mui/material/Slide"
import * as React from "react"
import { R_Display, R_Positions } from "../R_Style/R_Style"
type TimeoutProps = {
  appear: number;
  enter: number;
  exit: number;
};

type SimpleTransitionsSlideProps = {
  isActive: boolean //定義是否開啟
  children: React.ReactElement<any>
  position_row: "start" | "center" | "end"
  position_column: "start" | "center" | "end"
  transition: "up" | "left" | "right" | "down"
  style?: React.CSSProperties
  // timeout:TimeoutProps
  timeout: [number, number, number];
  touch:boolean
}

export default function SimpleTransitionsSlide(props: SimpleTransitionsSlideProps) {
  let row:any
  let column:any
  
  if(props.position_row==="start"){
    row = "flex-start"
  }  if(props.position_row==="center"){
    row = "center"
  }  if(props.position_row==="end"){
    row = "flex-end"
  }

  if(props.position_column==="start"){
    column = "flex-start"
  }  if(props.position_column==="center"){
    column = "center"
  }  if(props.position_column==="end"){
    column = "flex-end"
  }
  return (
    <Slide timeout={{appear:props.timeout[0],enter:props.timeout[1],exit:props.timeout[2]} } direction={props.transition} in={props.isActive} mountOnEnter unmountOnExit>
      <div
        style={{
          ...R_Display("row",row,column),
          zIndex: 5001,
          position: "fixed",
          bottom: 0,
          width:"100%",
          height:"100vh",
          pointerEvents: "none", // 確保滑動的 div 不攔截點擊事件
          ...props.style,
        }}
      >
        <div style={{ pointerEvents: `${props.touch?"auto":"none"}`}}>
        {props.children}
        </div>
      </div>
    </Slide>
  )
}
