import { Chip, Stack } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import  { R_Display_bata } from "../R_Style/R_Style";
import { error } from "console";

type TagFilterProps = {
  DataState: Array<any>;
  Onchange: Function;
  TagList: Array<any>;
};

export function TagFilter(Props: TagFilterProps) {
  const [checkButton, setcheckButton] = useState(0);
  const TagButtonList: Array<ReactElement> = [];

  async function handlefilter(filter: any, index: number) {
    setcheckButton(index);
    let result;
    if (index === 0) {
      result = Props.DataState;
    } else if (index > 0) {
      result = Props.DataState.filter((x) => x.tag.includes(filter));
    } else {
      throw new Error("TagFilter.jsx Error");
    }
    Props.Onchange(result);
    console.log(result);
  }

  Props.TagList.map((tag, tagIndex) => {
    const chips = (
      <Chip
        sx={{ marginRight: "10px" }}
        key={tagIndex}
        label={tag}
        variant={checkButton == tagIndex ? "filled" : "outlined"}
        onClick={() => handlefilter(tag, tagIndex)}
      />
    );
    TagButtonList.push(chips);
  });
/* 順序：上 右 下 左 */
// margin: 10px 15px 20px 25px;
  return <div style={{ ...R_Display_bata().display_row, margin: "20px 0px 20px 20px", overflow: "auto" }}>{TagButtonList.map((n) => n)}</div>;
}
