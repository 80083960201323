import { useEffect } from "react"
import { R_Display, R_Display_bata } from "../../R_Style/R_Style"
import "./Footer.css"
import InstagramIcon from "@mui/icons-material/Instagram"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion"
import GitHubIcon from '@mui/icons-material/GitHub';

type props = {
  style?: React.CSSProperties
}


export default function Footer(Props: props) {
  return (
    <footer className="site-footer" style={Props.style}>
      <div className="mainContent">
        <div style={{ marginBlock: "10px ", ...R_Display("column", "center", "flex-start") }}>
        <img src="/@R036_images/main/R036-LOGO(白色).svg" style={{ width: "60px", marginBottom: "10px" }}/>
        {/* <h5>軟體生活 v0.3.6 是一個理念象徵，一個專注在資訊科技與人文的地方</h5>         */}
        {/* <h5>是一個理念象徵，一個專注在資訊科技與人文的地方</h5>         */}
          
        <p className="footer_content">軟體生活 v0.3.6 是一個理念象徵，一個專注在資訊科技與人文的地方</p>
          
          {/* <p className="footer_content">象徵著不斷更新的版本，在這個數位時代，生活就像軟體一樣，不斷進行更新，每一次經驗、每一個挑戰，都為生活帶來了新的修補與升級</p> */}
        </div>
        <p style={{ marginBlock: "10px ", ...R_Display("row", "flex-start", "center") }}>
          {/* <a style={{ marginRight: "15px" }} href="https://www.instagram.com/art.code.design/">
            <InstagramIcon />
          </a> */}
          <a style={{ marginRight: "15px" }} href="https://github.com/Rayder-R">
            {/* <AutoAwesomeMotionIcon/> */}
            <GitHubIcon style={{ width: "30px", filter: "grayscale(100%)" }} />

          </a>
          <a style={{ marginRight: "15px" }} href="https://vocus.cc/salon/R036">
            {/* <AutoAwesomeMotionIcon/> */}
            {/* <img src="/@R036_images/icon/vocus.svg" style={{ width: "80px", filter: "grayscale(100%)" }}></img> */}
          </a>
        </p>
        <hr />
      </div>
      <p className="footer_info ">Copyright &copy; 2023-2024 All Rights Reserved by Rayder </p>
      <div style={{ ...R_Display("row", "center", "flex-start") }}>
        
        <span style={{ fontSize: "10px", color: "gray" }}>版本 1.4.0</span>
      </div>
    </footer>
  )
}
