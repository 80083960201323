import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
// import './article.css'; // 假設你有一個專門的 CSS 文件來樣式化文章
import './articlePage.css'
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

import { dracula } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { Link, useParams, useLocation } from 'react-router-dom';
import ArticleAPI from '../../ServerAPI/ArticleAPI';
import ResponsiveAppBar from '../../@R036/R_Mui/AppBar';
import Button_two from '../../@R036/R_Base/Button/button_two';
import SimpleTransitionsSlide from '../../@R036/R_Mui/TransitionsSlide';
import DrawerList from '../../Component/drawerList';
import Backdrop from '../../@R036/R_Base/backdrop/backdropBase';
import BlogTitle from '../../@R036/R_Base/BlogTitle/BlogTitle';
import Footer from '../../@R036/R_Base/Footer/Footer';
import LoadingPage from '../home/loadingPage';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
type Props = {
  articleName?: string
}

function ArticlePage(props: Props) {
  const [isMenu, set_isMenu] = useState(false)
  const { type, id } = useParams();
  const [markdown, setMarkdown] = useState<string>('');

  async function fetchDataApi() {
    try {
      console.log("API loading...")
      const articleAPI = new ArticleAPI()
      // const result = await articleAPI.Read_articlePage(type!,id!)

      const result = await articleAPI.Read_articleFiles(type!, id!)


      setMarkdown(result!)
      // console.log("result" + result)
      set_isLoading(true)
      setTimeout(() => {
        // setIsLoading(false)
      }, 300)
    } catch (error) {
      console.error(error)
    }
  }

  const location = useLocation();
  //初始載入資料
  useEffect(() => {

    console.log(location.pathname); // 獲取當前路徑

    if (location.pathname.includes('sci-fi')) {
      console.log('路徑中包含 sci-fi');
      set_isscifi(true)
    } else {
      set_isscifi(false)
      console.log('路徑中不包含 sci-fi');
    }


    fetchDataApi()
  }, [])
  const [isLoading, set_isLoading] = React.useState(false)
  const [isscifi, set_isscifi] = React.useState(false)

  return (

    <>
      {isLoading ? <></> : <LoadingPage />}
      <ResponsiveAppBar
        rightTool={[
          <Button_two
            active={() => {
              set_isMenu(!isMenu)
            }}
            isOpen={isMenu}
            colorModel="black"
          />,
        ]}
        style={{ boxShadow: "none" }}
        Logo={<BlogTitle use_my_logo={true} style={{ height: "30px" }} color={"black"} name={""} />}
        Opend={() => set_isMenu(!isMenu)}
        color_Model={"black"}
      >
        <>
          <SimpleTransitionsSlide touch timeout={[1000, 400, 250]} transition={"left"} isActive={isMenu} position_row={"end"} position_column={"start"}>
            <div style={{ zIndex: 5001, height: "100vh" }}>
              <DrawerList
                active={() => {
                  set_isMenu(!isMenu)
                }}
              />
            </div>
          </SimpleTransitionsSlide>
          <Backdrop
            open={isMenu}
            set_open={function (): void {
              set_isMenu(!isMenu)
            }}
          ></Backdrop>
        </>
      </ResponsiveAppBar>

      <div className="markdown-container">
        <Link style={{ color: "black" }} to={`${isscifi?'/movieRoom':'/article'}`}><Button color='inherit'><ArrowBackIcon /><span style={{ fontFamily: "Noto Sans TC, sans-serif" }}>返回</span></Button></Link>

        {<ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}
          components={{
            code({ node, inline, className, children, ...props }: any) {
              const match = /language-(\w+)/.exec(className || '');

              return !inline && match ? (
                <SyntaxHighlighter style={dracula} PreTag="div" language={match[1]} {...props}>
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
          }}

        >{markdown}</ReactMarkdown>}
      </div>
      <Footer />

    </>
  );
};

export default ArticlePage;
