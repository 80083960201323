import React from "react";
import "./ArticleCard2.css";
import { Link } from "react-router-dom";

type Props = {
  id: number;
  title: string;
  date: string;
  tag: Array<string>;
  img: string;
  url: string;
  style?: React.CSSProperties;
};



export default function ArticleCard2(Props: Props) {
  const handleClick = () => {
    window.scrollTo(0, 0); // 滾動到頁面頂部
  };
  return (
    <section style={Props.style} className="ArticleCard2">
      <Link onClick={handleClick} to={`${Props.url}`}>
          <div className="Article_img" style={{ backgroundImage: `url('${Props.img}')` }} />
          <div className="content">
            <p className="title">{Props.title}</p>
            <p className="date">{new Date(Props.date).toLocaleDateString('zh-TW')}</p>
            <div className="tag-box">
              {Props.tag.map((n, index) => (
                <p key={index} className="tag">
                  {n}
                </p>
              ))}
            </div>
          </div>
      </Link>
    </section>
  );
}
