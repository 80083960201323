import { Box, Button, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { R_Display } from "../../@R036/R_Style/R_Style"
import BlogTitle from "../../@R036/R_Base/BlogTitle/BlogTitle"
export default function LoginPage() {
  const [account, setAccount] = useState("")
  const [password, setPassword] = useState("")
  const [token, setToken] = useState(null)

  const navigate = useNavigate()

  // useEffect(() => {
  //   if (sessionStorage.getItem("token")) {
  //     console.log("it ok")
  //     navigate("/blog/6f0f4816-0ff4-4215-9eb3-2091fa846e9e/back_system")
  //   }
  // }, [token])

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    try {
      // const response = await fetch("http://localhost:3002/api/login", {
      const response = await fetch(`https://r036-center.vercel.app/api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set the correct Content-Type
        },
        body: JSON.stringify({ account, password }), // Send JSON data
      })

      console.log(JSON.stringify({ account, password }))
      const Result = JSON.parse(await response.text())
      console.log("Response body:", Result.token)

      if (response.ok) {
        sessionStorage.setItem("token", Result.token)
        setToken(Result.token)
        navigate("/blog/6f0f4816-0ff4-4215-9eb3-2091fa846e9e/back_system")
      }
    } catch (error) {
      console.error("An error occurred:", error)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      console.log(window.innerWidth)
    }

    handleResize()

    // 監聽視窗大小變化事件
    window.addEventListener("resize", handleResize)

    // 清理事件監聽器
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <Box style={{ width: "100%", height: "100%", ...R_Display("column", "center", "center") }}>
      <div style={{ backgroundColor: "#FCFCFC", borderRadius: "30px", padding: "100px", boxShadow: "0px 5px 10px 1px rgba(0, 0, 0, 0.2)" }}>
        <div style={{...R_Display("row","flex-start","center")}}>
          <img style={{ width: "80px"}} src="/@R036_images/main/R036-LOGO(灰色).svg" alt="SVG Image" />
        </div>
        <form id="login-form" onSubmit={handleSubmit}>
          <div className="input email">
            <TextField label="Account" variant="standard" value={account} onChange={(e) => setAccount(e.target.value)} />
          </div>
          <div className="input password">
            <div className="dots"></div>
            <TextField label="Password" variant="standard" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>
          <Button type="submit" variant="contained" style={{ marginTop: "10px", backgroundColor: "gray" }}>
            <span>Login</span>
          </Button>
        </form>
      </div>
    </Box>
  )
}
