import React from "react";
import "./TopBar.css";
import { Link, useLocation } from "react-router-dom";

type TopBarProps = {
  style?: React.CSSProperties
  title_List: Array<{ title: string, scr: string }>
  color?: string
  color_Model?: "black" | "white"
}

export default function TopBar(Props: TopBarProps) {

  const location = useLocation();
  const splitArray = location.pathname.split("/")
  // console.log("當前路徑"+location.pathname)
  // console.log("當前路徑"+splitArray[splitArray.length - 1])
  const activeIndex = Props.title_List.findIndex((n)=>(n.scr.includes(splitArray[splitArray.length - 1])))
  
  const color = `${Props.color}80`
  const acriveColor = `${Props.color}100`
  const active = Props.color_Model ==="black"? "active_black" : "active_white"
  return (
      <section id="maintopbar" style={Props.style}>
        <nav style={{color:color}}>
          <ul>
            {Props.title_List.map((n,index) => (
              <li key={index}>
                <Link to={n.scr} className={activeIndex===index?active:""}>{n.title}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </section>
  );
}