import React, { useEffect } from "react";
import "./backdropBase.css";

type BackdropProps = {
  children?: React.ReactNode;
  backdrop?: boolean;
  open: boolean;
  set_open: Function;
};

export default function Backdrop(Props: BackdropProps) {
  useEffect(() => {
    if (Props.open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    // 清理效果
    // return () => {
    //   document.body.style.overflow = 'auto';
    // };
  }, [Props.open]);

  if (!Props.open) {
    return null;
  }

  return (
    <div className="backdrop backdrop-wrapper">
     
      {/* 顯示子類 */}
      {Props.open || Props.backdrop ? (
        <div className="backdrop-content">
          {Props.children}
        </div>
      ) : null}

      {/* 黑色遮罩 */}
      {Props.open ? (
        <div
          className="box"
          onClick={() => {
            Props.set_open();
          }}
        ></div>
      ) : null}
    </div>
  );
}
